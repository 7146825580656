import { Box, CircularProgress, Grid, Paper, useTheme } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import * as _ from "lodash";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { border, cardShadow } from "../../assets/jss/commonStyles";
import GeoLocationBlock from "./geoLocationBlock";
import ChannelGraph from "./graphs/channelGraph";
import DayAndHourGraph from "./graphs/dayAndHourGraph";
import DeviceGraph from "./graphs/deviceGraph";
import GroupTrendChart from "./graphs/groupTrendChart";
import DayHourBarGraph, { weekOrder } from "./graphs/hourGraph";

var countries = require("country-data").countries;

export const CustomPaper = withStyles((theme) => ({
  outlined: {
    border: border,
  },
  rounded: {
    borderRadius: 6,
  },
}))(Paper);

export let dayList,
  hourList,
  stateList,
  deviceList,
  channelList,
  countryList,
  graphRegion;

function CommonParamPage(props) {
  const {
    selectedSeg,
    application_states,
    classes,
    property,
    orderData,
    compareData,
    durationFrom,
    durationTo,
    compareFrom,
    compareTo,
    businessProfile,
    hasLXRTracking,
    dataSourceLength,
    trackingReady,
  } = props;
  const theme = useTheme();

  const segment_order_reducer = useSelector(
    (state) => state?.segment_order_reducer
  );

  const filterStates = useSelector((state) => state?.filterStates);

  useEffect(() => {
    handle_loading();
    //eslint-disable-next-line
  }, [orderData, compareData, application_states]);
  let monthCompare = application_states.monthCompare;
  const [loading, setLoading] = React.useState(true);
  // let dataCountries = _.uniq(_.map(orderData.orders, _.property('country_code')));
  graphRegion = businessProfile.country_codes
    ? businessProfile.country_codes[0] === "US"
      ? "usa"
      : "world"
    : null;

  const filter = useMemo(() => {
    switch (selectedSeg) {
      case "all":
        return filterStates?.filterAll;
      case "a":
        return filterStates?.filterA;
      case "b":
        return filterStates?.filterB;
      case "c":
        return filterStates?.filterC;
      default:
        return {};
    }
  }, [selectedSeg, filterStates]);

  let finalOrderData = { ...orderData };
  if (finalOrderData.orders) {
    finalOrderData.orders.forEach((t) => {
      t.day_of_week = moment(t.date).format("dddd");
      t.country = countries[t.country_code].name;
    });
  }
  let finalCompareOrderData = { ...compareData };
  if (finalCompareOrderData.orders) {
    finalCompareOrderData.orders.forEach((t) => {
      t.day_of_week = moment(t.date).format("dddd");
      t.country = countries[t.country_code].name;
    });
  }

  let filteredOrders = _.map(_.filter(finalOrderData.orders, filter));
  let filteredOrderData = { ...finalOrderData, orders: filteredOrders };

  let filteredCompareOrders = _.map(
    _.filter(finalCompareOrderData.orders, filter)
  );
  let filteredCompareOrderData = {
    ...finalCompareOrderData,
    orders: filteredCompareOrders,
  };

  hourList = _.uniq(_.map(finalOrderData.orders, "hour")).sort(function (a, b) {
    return a - b;
  });
  dayList = _.uniq(_.map(finalOrderData.orders, "day_of_week")).sort(
    (a, b) => weekOrder[a] - weekOrder[b]
  );
  stateList = _.pull(
    _.uniq(_.map(finalOrderData.orders, "region")),
    "Others"
  ).sort();
  countryList = _.uniq(_.map(finalOrderData.orders, "country")).sort();
  deviceList = _.some(finalOrderData.orders, "device")
    ? _.uniq(_.map(finalOrderData.orders, "device")).sort()
    : [];
  channelList = _.some(finalOrderData.orders, "channel")
    ? _.uniq(_.map(finalOrderData.orders, "channel")).sort()
    : [];

  function handle_loading() {
    if (orderData.isLoading === false && compareData.isLoading === false) {
      if (orderData.orders) {
        if (
          moment(application_states[durationFrom]).diff(
            moment(orderData.start, "DD-MM-yyyy"),
            "days"
          ) === 0 &&
          moment(application_states[durationTo]).diff(
            moment(orderData.end, "DD-MM-yyyy"),
            "days"
          ) === 0
        ) {
          if (monthCompare) {
            if (compareData.orders) {
              if (
                moment(application_states[compareFrom]).diff(
                  moment(compareData.start, "DD-MM-yyyy"),
                  "days"
                ) === 0 &&
                moment(application_states[compareTo]).diff(
                  moment(compareData.end, "DD-MM-yyyy"),
                  "days"
                ) === 0
              ) {
                setLoading(false);
                return null;
              }
            }
          } else {
            setLoading(false);
            return null;
          }
        }
      }
    }
    setLoading(true);
    return null;
  }
  return (
    <React.Fragment>
      {!loading ? (
        <div>
          <Box m={1} boxShadow={cardShadow}>
            <CustomPaper variant={"outlined"}>
              <GroupTrendChart
                property={property}
                data={filteredOrderData}
                compareData={filteredCompareOrderData}
              />
            </CustomPaper>
          </Box>
          <Box m={1} boxShadow={cardShadow}>
            {orderData ? (
              <CustomPaper variant={"outlined"}>
                <Grid container style={{ minHeight: 350 }}>
                  <Grid item md={6} style={{ borderRight: border }}>
                    <DayAndHourGraph
                      selectedSeg={selectedSeg}
                      property={property}
                      data={filteredOrderData}
                      compareData={filteredCompareOrderData}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <DayHourBarGraph
                      selectedSeg={selectedSeg}
                      classes={classes}
                      property={property}
                      orderData={filteredOrderData}
                      compareData={filteredCompareOrderData}
                    />
                  </Grid>
                </Grid>
              </CustomPaper>
            ) : (
              <CircularProgress />
            )}
          </Box>
          <Box m={1} boxShadow={cardShadow}>
            {orderData ? (
              <CustomPaper variant={"outlined"}>
                <GeoLocationBlock
                  graphRegion={graphRegion}
                  selectedSeg={selectedSeg}
                  property={property}
                  orderData={filteredOrderData}
                  compareData={filteredCompareOrderData}
                />
              </CustomPaper>
            ) : (
              <CircularProgress />
            )}
          </Box>
          <Box m={1} boxShadow={cardShadow}>
            {!orderData.isLoading && orderData ? (
              <CustomPaper variant={"outlined"}>
                <DeviceGraph
                  selectedSeg={selectedSeg}
                  property={property}
                  orderData={filteredOrderData}
                  compareData={filteredCompareOrderData}
                  hasLXRTracking={hasLXRTracking}
                  dataSourceLength={dataSourceLength}
                  trackingReady={trackingReady}
                />
              </CustomPaper>
            ) : (
              <CircularProgress />
            )}
          </Box>
          <Box m={1} boxShadow={cardShadow}>
            {!orderData.isLoading && orderData ? (
              <CustomPaper variant={"outlined"}>
                <ChannelGraph
                  selectedSeg={selectedSeg}
                  property={property}
                  orderData={filteredOrderData}
                  hasLXRTracking={hasLXRTracking}
                  compareData={filteredCompareOrderData}
                  dataSourceLength={dataSourceLength}
                  trackingReady={trackingReady}
                />
              </CustomPaper>
            ) : (
              <CircularProgress />
            )}
          </Box>
        </div>
      ) : (
        <Box style={{ minHeight: 150 }}>
          <CircularProgress
            size={100}
            style={{
              position: "relative",
              left: "50%",
              top: "50%",
              color: theme.palette.secondary.light,
            }}
          />
        </Box>
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  filterStates: state.filterStates,
  application_states: state.application_states,
  businessProfile: state.businessProfile,
  userProfile: state.userProfile,
});
export default connect(mapStateToProps, null)(CommonParamPage);
