import makeStyles from '@mui/styles/makeStyles';
import {drawerWidth} from "../../../components/sidebar";
import {border} from "../commonStyles";

const HeaderStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.common.white,
    // zIndex: `${theme.zIndex.drawer + 1} !important`,
    boxShadow: "0px 2px 14px rgba(0, 0, 0, 0.05)",
  },
  loginButton: {
    padding: "0px 16px",
    [theme.breakpoints.down(960)]: {
      padding: "6px",
    },
  },
  loginButtonTypo: {
    fontSize: "1rem",
    textTransform: "none",
    [theme.breakpoints.down(960)]: {
      fontSize: "12px",
      textTransform: "none",
    },
  },
  logo: {
    [theme.breakpoints.down(960)]: {
      width: "80%",
    },
  },
  flexGrow1: {
    flexGrow: 1,
  },
  userName: {
    color: theme.palette.text.primary,
  },
  notificationIcon: {
    fontSize: 24,
    borderRadius: "0px",
    color: theme.palette.text.primary,
    borderLeft: border,
  },
  helpCenterIcon: {
    fontSize: 24,
    borderRadius: "0px",
    color: theme.palette.text.primary,
    borderLeft: border,
  },
  menuItemIcon: {
    fontSize: 24,
    minWidth: 24,
    width:24,
    height:24,
  },
  scrollbar: {
    "&::-webkit-scrollbar": {
      width: "0.4em",
      height: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "6px",
    },
  },
  shrinkDrawer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  expandDrawer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white + " !important",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    zIndex: theme.zIndex.appBar - 1,
      "&::-webkit-scrollbar": {
          width: "0.3em",
          height: "0.4em",
      },
      "&::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "&::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(217, 217, 217, 0.5)",
          borderRadius: "6px",
      },
  },
  drawerClose: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white + " !important",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 104,
    overflowX: "hidden",
    zIndex: theme.zIndex.appBar - 1,
      "&::-webkit-scrollbar": {
          width: "0.3em",
          height: "0.4em",
      },
      "&::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "&::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(217, 217, 217, 0.5)",
          borderRadius: "6px",
      },
  },
  drawerContainer: {
    overflow: "auto",
  },
  popperPaper: {
    border: border,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
  },
  popper: {
    zIndex: 1500,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
  },
  arrow: {
    position: "absolute",
    fontSize: 7,
    width: "3em",
    height: "3em",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
      borderColor: "#e3e4f6",
    },
  },
  popoverClass: {
    marginTop: 10,
    border: border,
    "&::before": {
      content: "",
      position: "absolute",
      top: -20,
      right: 5,
      borderBottom: "10px solid #ffffff",
      borderRight: "10px solid transparent",
      borderLeft: "10px solid transparent",
      borderTop: "10px solid transparent",
      zIndex: 10,
    },
  },
  separatorClass: {
    marginLeft: 0,
    marginRight: 0,
    color: theme.palette.primary.main,
    opacity: 0.5,
  },
  afterHover: {
    width: 235,
  },
  beforeHover: {
    width: 80,
  },
  containerSec: {
    maxWidth: "1080px",
    margin: "auto",
  },
}));

export default HeaderStyles;