import { combineReducers } from "redux";
// import * as _ from 'lodash';
// import * as types from "../actions/actionTypes";
import {
  application_states,
  audience_history_data_reducer,
  auth_url_data_reducer,
  auth_var_data_reducer_fb,
  auth_var_data_reducer_ga,
  bigcomIntegrateReducer,
  businessProfile,
  churn_prediction_reducer,
  contactus_reducer,
  createExperimentReducer,
  customer_report_custom_reducer,
  customerTrendReducer,
  dataStatusOrderItemsReducer,
  dataStatusOrderReducer,
  dataStatusTrackingInfoReducer,
  delete_channel_reducer,
  delete_custom_filter_reducer,
  downtime_banner_reducer,
  edit_audience_reducer,
  emailResend,
  emailVerification,
  expAudienceReducer,
  expBudgetReducer,
  expCampaignReducer,
  expLaunchReducer,
  fb_auth_url_data_reducer,
  fetch_custom_filter_reducer,
  getCustomFilterAudiencesReducer,
  fetchAllExperimentListReducer,
  fetchAndClusterReducer,
  fetchExperimentReducer,
  filterStates,
  forgotPasswordReducer,
  generateContentReducer,
  generateMoreContentReducer,
  get_custom_filter_audience_reducer,
  googlead_active_audience_reducer,
  googlead_analytics_data_reducer,
  googlead_compare_analytics_data_reducer,
  helpCenter,
  list_audiences_reducer,
  loginAuthentication,
  MFAEnableReducer,
  MFAVerficationReducer,
  notification_data_reducer,
  overview_reducer,
  passwordChange,
  population_data_reducer,
  predictions_summary_reducer,
  priceHistogramReducer,
  productBundleReducer,
  productCategoryDataReducer,
  productDataReducer,
  productPageMetaDataReducer,
  productReportReducer,
  productTourReducer,
  push_audiences_fb_reducer,
  push_audiences_reducer,
  redeal_analytics_data_reducer,
  redeal_compare_analytics_data_reducer,
  refreshToken,
  remarket_page_states,
  repeat_next_3months_reducer,
  requestFreeDemoReducer,
  resetPasswordReducer,
  save_custom_filter_reducer,
  segment_compare_order_reducer,
  segment_compare_path_reducer,
  segment_compare_product_reducer,
  segment_order_reducer,
  segment_path_reducer,
  segment_product_reducer,
  set_fbid_reducer,
  set_gaid_reducer,
  set_redealid_reducer,
  shopifyAccessTokenReducer,
  shopifyBuIdAccessTokenReducer,
  shopifyDataProgressReducer,
  shopifyDataStatusOrderItemsReducer,
  shopifyDataStatusOrderReducer,
  shopifyDataStatusTrackingInfoReducer,
  shopifyHMACreducer,
  shopifyIntegrateReducer,
  signUpReducer,
  single_buyer_repeat_reducer,
  trackingScript,
  update_replace_existing_list_fb_reducer,
  update_replace_existing_list_reducer,
  user_segment_reducer,
  userProfile,
  visitor_to_customer_reducer,
  websiteDetailsReducer,
  fetchExperimentExecutionStatus,
  fetchPredefinedExpReducer,
  fetchExpResultTestControlStatsReducer,
  fetchExpResultCampStatsReducer,
  fetchDatasourcesReducer,
  deleteDatasourcesReducer,
  deleteExperimentReducer,
  fetchCustomerMigrationReducer,
  fetchInmarketAffinityReducer,
  hashed_emails_reducer,
  expFbAudienceReducer,
  expFbCampaignReducer,
  expFbAdsetReducer,
} from "./reducers";

const appReducer = combineReducers({
  loginAuthentication,
  refreshToken,
  passwordChange,
  requestFreeDemoReducer,
  userProfile,
  overview_reducer,
  application_states,
  segment_order_reducer,
  segment_path_reducer,
  segment_product_reducer,
  population_data_reducer,
  segment_compare_order_reducer,
  segment_compare_path_reducer,
  segment_compare_product_reducer,
  user_segment_reducer,
  notification_data_reducer,
  filterStates,
  auth_url_data_reducer,
  fb_auth_url_data_reducer,
  auth_var_data_reducer_ga,
  auth_var_data_reducer_fb,
  set_gaid_reducer,
  set_fbid_reducer,
  delete_channel_reducer,
  push_audiences_reducer,
  push_audiences_fb_reducer,
  list_audiences_reducer,
  googlead_analytics_data_reducer,
  googlead_active_audience_reducer,
  redeal_analytics_data_reducer,
  set_redealid_reducer,
  single_buyer_repeat_reducer,
  repeat_next_3months_reducer,
  visitor_to_customer_reducer,
  predictions_summary_reducer,
  audience_history_data_reducer,
  update_replace_existing_list_reducer,
  update_replace_existing_list_fb_reducer,
  edit_audience_reducer,
  redeal_compare_analytics_data_reducer,
  googlead_compare_analytics_data_reducer,
  signUpReducer,
  emailVerification,
  websiteDetailsReducer,
  trackingScript,
  emailResend,
  businessProfile,
  resetPasswordReducer,
  forgotPasswordReducer,
  helpCenter,
  contactus_reducer,
  dataStatusOrderReducer,
  dataStatusOrderItemsReducer,
  dataStatusTrackingInfoReducer,
  productTourReducer,
  customerTrendReducer,
  churn_prediction_reducer,
  downtime_banner_reducer,
  fetch_custom_filter_reducer,
  getCustomFilterAudiencesReducer,
  customer_report_custom_reducer,
  generateContentReducer,
  generateMoreContentReducer,
  shopifyIntegrateReducer,
  bigcomIntegrateReducer,
  shopifyAccessTokenReducer,
  shopifyDataStatusOrderReducer,
  shopifyDataStatusOrderItemsReducer,
  shopifyDataStatusTrackingInfoReducer,
  shopifyDataProgressReducer,
  fetchAndClusterReducer,
  shopifyHMACreducer,
  shopifyBuIdAccessTokenReducer,
  save_custom_filter_reducer,
  remarket_page_states,
  delete_custom_filter_reducer,
  productBundleReducer,
  productDataReducer,
  productCategoryDataReducer,
  productPageMetaDataReducer,
  get_custom_filter_audience_reducer,
  MFAEnableReducer,
  MFAVerficationReducer,
  priceHistogramReducer,
  productReportReducer,
  createExperimentReducer,
  expAudienceReducer,
  expCampaignReducer,
  expBudgetReducer,
  fetchExperimentReducer,
  expLaunchReducer,
  fetchAllExperimentListReducer,
  fetchPredefinedExpReducer,
  fetchExperimentExecutionStatus,
  fetchExpResultTestControlStatsReducer,
  fetchExpResultCampStatsReducer,
  fetchDatasourcesReducer,
  deleteDatasourcesReducer,
  deleteExperimentReducer,
  fetchCustomerMigrationReducer,
  hashed_emails_reducer,
  fetchInmarketAffinityReducer,
  expFbAudienceReducer,
  expFbCampaignReducer,
  expFbAdsetReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
