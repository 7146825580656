import * as types from "../actions/actionTypes";
import axios from "axios";
import { csrftoken } from "../components/csrfToken";
import * as _ from "lodash";
import moment from "moment";
import { connectionsURLValue, settingConnectionsURL } from "../components/sidebar";

export const apiSuccess = "API SUCCESS";
export const apiFailed = "API FAILED";
export const SHOPIFYREAUTHSUCCESS = "SHOPIFYREAUTHSUCCESS"

export const StoredSegmentData = {};

export function handle_login(e, data) {
  return async function (dispatch) {
    const url = "/api/token/";
    dispatch({
      type: types.LOGIN_REQUEST,
    });
    try {
      let response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.LOGIN_SUCCESS,
        resultData,
      });
    } catch (e) {
      console.log("error in login= ", e);
      let failureData;
      if (e.response) {
        failureData = e.response;
      }
      dispatch({
        type: types.LOGIN_FAILURE,
        failureData,
      });
    }
  };
}

export function handle_sign_up(e, data) {
  return async function (dispatch) {
    const url = "/api/core/lxrinsights_create_account/";
    dispatch({
      type: types.SIGN_UP_REQUEST,
    });
    try {
      let response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.SIGN_UP_SUCCESS,
        resultData,
      });
    } catch (e) {
      console.log("error in sign up= ", e);
      let resultData;
      if (e.response) {
        resultData = e.response;
      }
      dispatch({
        type: types.SIGN_UP_FAILURE,
        resultData,
      });
    }
  };
}

export function forgot_password_email(e, data) {
  return async function (dispatch) {
    const url = "/api/core/request_reset_password_email/";
    dispatch({
      type: types.FORGOT_PASSWORD_REQUEST,
    });
    try {
      let response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.FORGOT_PASSWORD_SUCCESS,
        resultData,
      });
    } catch (e) {
      console.log("error in sign up= ", e);
      let resultData;
      if (e.response) {
        resultData = e.response;
      }
      dispatch({
        type: types.FORGOT_PASSWORD_FAILURE,
        resultData,
      });
    }
  };
}

export function reset_password(e, data) {
  return async function (dispatch) {
    const url = "/api/core/password_reset_complete/";
    dispatch({
      type: types.RESET_PASSWORD_REQUEST,
    });
    try {
      let response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.RESET_PASSWORD_SUCCESS,
        resultData,
      });
    } catch (e) {
      console.log("error in sign up= ", e);
      let resultData;
      if (e.response) {
        resultData = e.response;
      }
      dispatch({
        type: types.RESET_PASSWORD_FAILURE,
        resultData,
      });
    }
  };
}

export function add_website_details(data) {
  let finalData = {};
  finalData["website_name"] = data["website_name"];
  finalData["domain"] = data["domain_name"];
  finalData["ecommerce_platform"] = data["ecommerce_platform"];
  finalData["other_ecommerce_platform"] = data["ecommerce_platform_field"];
  finalData["currency"] = data["currency"];
  if (data["region"] === "global") {
    finalData["countries"] = "";
  } else {
    finalData["countries"] = data["region"];
  }
  finalData["time_zone"] = data["time_zone"];
  finalData["tag_management_tool"] = data["tag_manager"];
  finalData["other_tag_management_tool"] = data["tag_manager_field"];
  return async function (dispatch, getState) {
    const property_id = getState().userProfile.bu_id;
    let url;
    if (property_id) {
      url = `/api/core/store_website_details?property_id=${property_id}`;
    } else {
      url = "/api/core/store_website_details";
    }
    dispatch({
      type: types.WEBSITE_DETAILS_REQUEST,
    });
    try {
      let response = await axios.post(url, finalData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      // let resultData = await response.data;
      dispatch({
        type: types.WEBSITE_DETAILS_SUCCESS,
        response,
      });
    } catch (e) {
      console.log("error in fetch= ", e);
      dispatch({
        type: types.WEBSITE_DETAILS_FAILURE,
      });
    }
  };
}

export function add_shopify_details(e, data) {
  let finalData = {};
  const updatedStoreURL = data["store_url"].endsWith(".myshopify.com")
    ? data["store_url"] // If it already ends with .myshopify.com, leave it as is
    : data["store_url"] + ".myshopify.com"; // Append .myshopify.com if missing
  //    finalData['website_name'] = data['website_name'];
  finalData["store_url"] = updatedStoreURL;
  finalData["ecommerce_platform"] = "shopify";
  //    finalData['currency'] = data['currency'];
  //    if (data['region'] === 'global') {
  //        finalData['countries'] = ''
  //    } else {
  //        finalData['countries'] = data['region']
  //    }
  //    finalData['time_zone'] = data['time_zone'];
  const redirectURL = window.location.port
    ? "http://" +
    window.location.hostname +
    ":" +
    window.location.port +
    "/?page=integrations"
    : "https://" + window.location.hostname + "/?page=integrations";

  finalData["redirect_url"] = redirectURL;

  finalData["re_auth"] = data?.re_auth ? data?.re_auth : false;

  const websiteURL = window.location.hash;

  // if (websiteURL.includes("customers/audience") || websiteURL.includes("customer-analytics") || websiteURL.includes("predictions")) {
  //   localStorage.setItem(
  //     "shopify_re_auth",
  //     data?.re_auth ? data?.re_auth : false
  //   );
  // } else {
  //   localStorage.setItem("shopify_re_auth", false);
  // }

  return async function (dispatch, getState) {
    const url = "/api/ecom/get-shopify-auth-url/";

    dispatch({
      type: types.SHOPIFY_INTEGRATE_REQUEST,
    });
    try {
      let response = await axios.post(url, finalData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      if(Boolean(localStorage.getItem("shopify_re_auth"))){
        localStorage.setItem("shopify_re_auth", 'success');
      }
      dispatch({
        type: types.SHOPIFY_INTEGRATE_SUCCESS,
        response,
      });
    } catch (e) {
      console.log("error in fetch= ", e);
      if(Boolean(localStorage.getItem("shopify_re_auth"))){
        localStorage.setItem("shopify_re_auth", 'fail');
      }
      let resultData;
      if (e.response) {
        resultData = e.response;
      }
      dispatch({
        type: types.SHOPIFY_INTEGRATE_FAILURE,
        resultData,
      });
    }
  };
}

export function add_bigcom_details(e, data) {
  let finalData = {};
  finalData["website_name"] = data["website_name"];
  finalData["store_hash"] = data["store_hash"];
  finalData["ecommerce_platform"] = "bigcommerce";
  finalData["currency"] = data["currency"];
  if (data["region"] === "global") {
    finalData["countries"] = "";
  } else {
    finalData["countries"] = data["region"];
  }
  finalData["time_zone"] = data["time_zone"];
  const redirectURL = window.location.port
    ? "http://" +
    window.location.hostname +
    ":" +
    window.location.port +
    "/?page=integrations"
    : "https://" + window.location.hostname + "/?page=integrations";
  finalData["redirect_url"] = redirectURL;
  return async function (dispatch) {
    const url = "/api/ecom/get-bigcom-auth-url/";
    dispatch({
      type: types.BIGCOM_INTEGRATE_REQUEST,
    });
    try {
      let response = await axios.post(url, finalData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      dispatch({
        type: types.BIGCOM_INTEGRATE_SUCCESS,
        response,
      });
    } catch (e) {
      console.log("error in fetch= ", e);
      dispatch({
        type: types.BIGCOM_INTEGRATE_FAILURE,
      });
    }
  };
}

export function get_shopify_access_token(
  code,
  redirectURL,
  currentURl,
  shopify_re_auth,
  property_id
) {
  return async function (dispatch, getState) {
    localStorage.removeItem("source");
    localStorage.removeItem("code");
    localStorage.removeItem("redirectURL");
    localStorage.removeItem("currentURl");
    let url;
    if (property_id) {
      url = `/api/ecom/get-shopify-access-token?property_id=${property_id}`;
    } else {
      url = "/api/ecom/get-shopify-access-token";
    }
    dispatch({
      type: types.SHOPIFY_ACCESS_REQUEST,
    });
    try {
      let response = await axios.post(
        url,
        {
          code: code,
          redirect_url: redirectURL,
          current_url: currentURl,
          re_auth: shopify_re_auth,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        }
      );
      let authResponse = await response.data;
      dispatch({
        type: types.SHOPIFY_ACCESS_SUCCESS,
        authResponse,
      });
      if(Boolean(localStorage.getItem('shopify_re_auth'))) {
        localStorage.setItem("shopify_re_auth", SHOPIFYREAUTHSUCCESS)
      }
    } catch (e) {
      console.log("error in shopify access token= ", e);
      let resultData;
      if (e.response) {
        resultData = e.response;
      }
      dispatch({
        type: types.SHOPIFY_ACCESS_FAILURE,
        resultData,
      });
    }
  };
}

export function get_bigcom_access_token(code, redirectURL, currentURl) {
  return async function (dispatch) {
    localStorage.removeItem("source");
    localStorage.removeItem("code");
    localStorage.removeItem("redirectURL");
    localStorage.removeItem("currentURl");
    const url = "/api/ecom/get-bigcom-access-token/";
    dispatch({
      type: types.BIGCOM_ACCESS_REQUEST,
    });
    try {
      let response = await axios.post(
        url,
        {
          code: code,
          redirect_url: redirectURL,
          current_url: currentURl,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        }
      );
      let authResponse = await response.data;
      // console.log("action response", authResponse)
      dispatch({
        type: types.BIGCOM_ACCESS_SUCCESS,
        authResponse,
      });
    } catch (e) {
      console.log("error in bigcom access token= ", e);
      dispatch({
        type: types.BIGCOM_ACCESS_FAILURE,
      });
    }
  };
}

export function email_verification(data) {
  return async function (dispatch) {
    const url = "/api/core/activate_account/";
    dispatch({
      type: types.EMAIL_VERIFICATION_REQUEST,
    });
    try {
      let response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.EMAIL_VERIFICATION_SUCCESS,
        resultData,
      });
    } catch (e) {
      console.log("error in email verification = ", e);
      dispatch({
        type: types.EMAIL_VERIFICATION_FAILURE,
      });
    }
  };
}

export function email_resend(e, data) {
  return async function (dispatch) {
    const url = "/api/core/resend_signup_email_confirmation/";
    dispatch({
      type: types.EMAIL_RESEND_REQUEST,
    });
    try {
      let response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.EMAIL_RESEND_SUCCESS,
        resultData,
      });
    } catch (e) {
      console.log("error in resending email = ", e);
      dispatch({
        type: types.EMAIL_RESEND_FAILURE,
      });
    }
  };
}

export function help_center(data) {
  return async function (dispatch) {
    const url = "/api/help-center/get_help/";
    dispatch({
      type: types.HELPCENTER_QUERY_REQUEST,
    });
    try {
      let response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.HELPCENTER_QUERY_SUCCESS,
        resultData,
      });
    } catch (e) {
      console.log("error in fetching query = ", e);
      dispatch({
        type: types.HELPCENTER_QUERY_FAILED,
      });
    }
  };
}

export function data_status_order() {
  return async function (dispatch) {
    const url = "/api/tracking/data_status_from_ne_order/";
    dispatch({
      type: types.DATA_STATUS_ORDER_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.DATA_STATUS_ORDER_SUCCESS,
        resultData,
      });
      // console.log(resultData,"review data status order")
    } catch (e) {
      console.log("error in data status order = ", e);
      let resultData;
      if (e.response) {
        resultData = e.response;
      }
      dispatch({
        type: types.DATA_STATUS_ORDER_FAILURE,
        resultData,
      });
    }
  };
}

export function data_status_order_item() {
  return async function (dispatch) {
    const url = "/api/tracking/data_status_from_ne_order_items/";
    dispatch({
      type: types.DATA_STATUS_ORDER_ITEM_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.DATA_STATUS_ORDER_ITEM_SUCCESS,
        resultData,
      });
      // console.log(resultData,"review data status order items")
    } catch (e) {
      console.log("error in data status order items = ", e);
      let resultData;
      if (e.response) {
        resultData = e.response;
      }
      dispatch({
        type: types.DATA_STATUS_ORDER_ITEM_FAILURE,
        resultData,
      });
    }
  };
}

export function data_status_tracking_info() {
  return async function (dispatch) {
    const url = "/api/tracking/data_status_from_ne_tracking_info/";
    dispatch({
      type: types.DATA_STATUS_TRACKINGINFO_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.DATA_STATUS_TRACKINGINFO_SUCCESS,
        resultData,
      });
      // console.log(resultData,"review data status tracking info")
    } catch (e) {
      console.log("error in data status tracking info = ", e);
      dispatch({
        type: types.DATA_STATUS_TRACKINGINFO_FAILURE,
      });
    }
  };
}

export function shopify_data_status_order() {
  return async function (dispatch) {
    const url = "api/ecom/shopify_ne_order_data_status/";
    dispatch({
      type: types.SHOPIFY_DATA_STATUS_ORDER_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.SHOPIFY_DATA_STATUS_ORDER_SUCCESS,
        resultData,
      });
      // console.log(resultData,"review data status order")
    } catch (e) {
      console.log("error in data status order = ", e);
      let resultData;
      if (e.response) {
        resultData = e.response;
      }
      dispatch({
        type: types.SHOPIFY_DATA_STATUS_ORDER_FAILURE,
        resultData,
      });
    }
  };
}

export function shopify_data_status_order_item() {
  return async function (dispatch) {
    const url = "/api/ecom/shopify_ne_order_items_data_status/";
    dispatch({
      type: types.SHOPIFY_DATA_STATUS_ORDER_ITEM_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.SHOPIFY_DATA_STATUS_ORDER_ITEM_SUCCESS,
        resultData,
      });
      // console.log(resultData,"review data status order items")
    } catch (e) {
      console.log("error in data status order items = ", e);
      let resultData;
      if (e.response) {
        resultData = e.response;
      }
      dispatch({
        type: types.SHOPIFY_DATA_STATUS_ORDER_ITEM_FAILURE,
        resultData,
      });
    }
  };
}

export function shopify_data_status_tracking_info() {
  return async function (dispatch) {
    const url = "/api/ecom/shopify_customers_data_status/";
    dispatch({
      type: types.SHOPIFY_DATA_STATUS_TRACKINGINFO_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.SHOPIFY_DATA_STATUS_TRACKINGINFO_SUCCESS,
        resultData,
      });
      // console.log(resultData,"review data status tracking info")
    } catch (e) {
      console.log("error in data status tracking info = ", e);
      dispatch({
        type: types.SHOPIFY_DATA_STATUS_TRACKINGINFO_FAILURE,
      });
    }
  };
}

export function shopify_data_progress() {
  return async function (dispatch) {
    const url = "/api/ecom/data-transfer-progress-shopify/";
    // const url = "/dataProgress.JSON";
    dispatch({
      type: types.SHOPIFY_DATA_PROGRESS_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.SHOPIFY_DATA_PROGRESS_SUCCESS,
        resultData,
      });
      // console.log(resultData,"review data status order items")
    } catch (e) {
      console.log("error in data progress = ", e);
      let resultData;
      if (e.response) {
        resultData = e.response;
      }
      dispatch({
        type: types.SHOPIFY_DATA_PROGRESS_FAILURE,
        resultData,
      });
    }
  };
}

export function tracking_script() {
  return async function (dispatch) {
    const url = "/api/tracking/scripts/";
    dispatch({
      type: types.TRACKING_SCRIPT_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.TRACKING_SCRIPT_SUCCESS,
        resultData,
      });
    } catch (e) {
      console.log("error in fetching tracking script = ", e);
      dispatch({
        type: types.TRACKING_SCRIPT_FAILURE,
      });
    }
  };
}

export function business_profile() {
  return async function (dispatch) {
    const url = "api/core/business_profile/";
    dispatch({
      type: types.BUSINESS_PROFILE_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.BUSINESS_PROFILE_SUCCESS,
        resultData,
      });
    } catch (e) {
      console.log("error in fetching business profile = ", e);
      dispatch({
        type: types.BUSINESS_PROFILE_FAILED,
      });
    }
  };
}

export function refresh_token() {
  return async function (dispatch) {
    const url = "/api/token/refresh/";
    dispatch({
      type: types.REFRESH_TOKEN_REQUEST,
    });
    try {
      let response = await axios.post(
        url,
        { refresh: `${localStorage.getItem("refresh")}` },
        {
          headers: {
            // "Content-Type": "application/json",
            grant_type: "refresh_token",
          },
        }
      );
      let resultData = await response.data;
      dispatch({
        type: types.REFRESH_TOKEN_SUCCESS,
        resultData,
      });
    } catch (e) {
      console.log("error in refreshtoken= ", e);
      dispatch({
        type: types.REFRESH_TOKEN_FAILURE,
      });
    }
  };
}

export function password_change(e, data) {
  return async function (dispatch) {
    const url = "/rest-auth/password/change/";
    dispatch({
      type: types.PASSWORD_CHANGE_REQUEST,
    });
    try {
      let response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
          "X-CSRFToken": csrftoken,
        },
      });
      dispatch({
        type: types.PASSWORD_CHANGE_SUCCESS,
        response,
      });
    } catch (e) {
      console.log("error in password change= ", e);
      let resultData;
      if (e.response) {
        resultData = e.response;
      }
      dispatch({
        type: types.PASSWORD_CHANGE_FAILURE,
        resultData,
      });
    }
  };
}

export function request_free_demo(e, data) {
  return async function (dispatch) {
    const url = "/api/requestdemos/";
    dispatch({
      type: types.REQUEST_FREE_DEMO_REQUEST,
    });
    try {
      let response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: types.REQUEST_FREE_DEMO_SUCCESS,
        response,
      });
    } catch (e) {
      console.log("error in fetch= ", e);
      dispatch({
        type: types.REQUEST_FREE_DEMO_FAILURE,
      });
    }
  };
}

export function get_user_data() {
  return async function (dispatch) {
    const url = "/core/current_user/";
    dispatch({
      type: types.USER_DETAILS_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.USER_DETAILS_SUCCESS,
        resultData,
      });
    } catch (e) {
      console.log("error in fetch= ", e);
      dispatch({
        type: types.USER_DETAILS_FAILURE,
      });
    }
  };
}

export function delete_custom_filter(filter_id) {
  return async function (dispatch) {
    const url = `/api/audience/customer-filters/${filter_id}`;
    dispatch({
      type: types.DELETE_CUSTOMER_FILTER_REQUEST,
    });
    try {
      let response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.DELETE_CUSTOMER_FILTER_SUCCESS,
        resultData,
      });
    } catch (e) {
      console.log("error in deleting customer filter= ", e);
      dispatch({
        type: types.DELETE_CUSTOMER_FILTER_FAILED,
      });
    }
  };
}

export function get_custom_filter_audiences(filter_id) {
  return async function (dispatch) {
    const url = `/api/audience/customer-filters/audiences/${filter_id}`;
    dispatch({
      type: types.AUDIENCES_CUSTOMER_FILTER_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.AUDIENCES_CUSTOMER_FILTER_SUCCESS,
        resultData,
      });
    } catch (e) {
      console.log("error in fetching audiences customer filter= ", e);
      dispatch({
        type: types.AUDIENCES_CUSTOMER_FILTER_FAILED,
      });
    }
  };
}

export function logoutUser() {
  return async function (dispatch) {
    dispatch({
      type: types.LOGOUT,
    });
  };
}

export function overview_compare(overcompvalue) {
  return async function (dispatch) {
    dispatch({
      type: types.OVERVIEW_COMPARE,
      overcompvalue,
    });
  };
}

export function set_window_height() {
  return async function (dispatch) {
    dispatch({
      type: types.SET_WINDOW_HEIGHT,
      screen_height: window.innerHeight,
    });
  };
}

export function login_popup(loginpopup) {
  return async function (dispatch) {
    dispatch({
      type: types.LOGIN_POPUP_CHANGE,
      loginpopup,
    });
  };
}

export function push_param_change(pushParamValue) {
  return async function (dispatch) {
    dispatch({
      type: types.PUSH_PARAM_CHANGE,
      pushParamValue,
    });
  };
}

export function push_priority_param_change(pushPriorityParamValue) {
  return async function (dispatch) {
    dispatch({
      type: types.PUSH_PRIORITY_PARAM_CHANGE,
      pushPriorityParamValue,
    });
  };
}

export function password_popup_showed() {
  return async function (dispatch) {
    dispatch({
      type: types.SET_SHOW_PASSWORD_POPUP,
      passwordPopupValue: false,
    });
  };
}

export function shopify_data_progress_value(shopifyDataProgressValue) {
  return async function (dispatch) {
    dispatch({
      type: types.SET_SHOPIFY_DATA_PROGRESS,
      shopifyDataProgressValue,
    });
  };
}

export function set_currency_fields(currency, currencySymbol) {
  return async function (dispatch) {
    dispatch({
      type: types.SET_CURRENCY_FIELDS,
      currency,
      currencySymbol,
    });
  };
}

export function customer_url_list(valueURL) {
  return async function (dispatch) {
    dispatch({
      type: types.SET_CUSTOMER_URL,
      valueURL,
    });
  };
}

export function customer_email_download(valueDownload) {
  return async function (dispatch) {
    dispatch({
      type: types.SET_CUSTOMER_EMAIL_DOWNLOAD,
      valueDownload,
    });
  };
}

export function segment_page_tab(pageTabValue) {
  return async function (dispatch) {
    dispatch({
      type: types.SET_SEGMENT_PAGE_TAB,
      pageTabValue,
    });
  };
}

export function report_page_tab(reportPageTabValue) {
  return async function (dispatch) {
    await dispatch({
      type: types.SET_REPORT_PAGE_TAB,
      reportPageTabValue,
    });
    await dispatch({
      type: types.SET_PAGE_TAB,
      pageTabValue: reportPageTabValue,
    });
    await dispatch({
      type: types.SET_RESET_VALUES_TO_ALL,
    });
  };
}

export function reset_user_filter() {
  return async function (dispatch) {
    dispatch({
      type: types.SET_RESET_USER_FILTER,
    });
  };
}

export function set_applied_filter_values(
  recencyValue,
  frequencyValue,
  monetaryValue,
  aovValue,
  ppvValue,
  hvcScoreValue,
  segmentAValue,
  segmentBValue,
  segmentCValue
) {
  return async function (dispatch, getState) {
    const nonZeroData = getState().remarket_page_states.nonZeroData;
    const pred_churn = getState().remarket_page_states.pred_churn;
    const hvcKey = getState().remarket_page_states.hvcKey;
    const pred_single_repeat_buyer =
      getState().remarket_page_states.pred_single_repeat_buyer;
    const pred_repeat_buyer_2m =
      getState().remarket_page_states.pred_repeat_buyer_2m;
    let rfmGraphData;
    rfmGraphData = _.map(
      _.filter(nonZeroData, function (d) {
        if (hvcKey) {
          return (
            d.recency >= recencyValue[0] &&
            d.recency <= recencyValue[1] &&
            d.hvc_score >= hvcScoreValue[0] &&
            d.hvc_score <= hvcScoreValue[1] &&
            d.frequency >= frequencyValue[0] &&
            d.frequency <= frequencyValue[1] &&
            d.monetory_value >= monetaryValue[0] &&
            d.monetory_value <= monetaryValue[1] &&
            d.aov >= aovValue[0] &&
            d.aov <= aovValue[1] &&
            d.clv >= ppvValue[0] &&
            d.clv <= ppvValue[1]
          );
        } else {
          return (
            d.recency >= recencyValue[0] &&
            d.recency <= recencyValue[1] &&
            d.frequency >= frequencyValue[0] &&
            d.frequency <= frequencyValue[1] &&
            d.monetory_value >= monetaryValue[0] &&
            d.monetory_value <= monetaryValue[1] &&
            d.aov >= aovValue[0] &&
            d.aov <= aovValue[1] &&
            d.clv >= ppvValue[0] &&
            d.clv <= ppvValue[1]
          );
        }
      })
    );
    rfmGraphData = _.map(
      _.filter(rfmGraphData, function (d) {
        let segArray = [];
        if (segmentAValue) {
          segArray.push("a");
        } else {
          _.pull(segArray, "a");
        }
        if (segmentBValue) {
          segArray.push("b");
        } else {
          _.pull(segArray, "b");
        }
        if (segmentCValue) {
          segArray.push("c");
        } else {
          _.pull(segArray, "c");
        }
        return segArray.includes(d.segment);
      })
    );
    if (pred_single_repeat_buyer) {
      rfmGraphData = _.filter(rfmGraphData, { repeat_purchaser1: 1 });
    } else if (pred_repeat_buyer_2m) {
      rfmGraphData = _.filter(rfmGraphData, { next2months_repeat_buyer: 1 });
    } else if (pred_churn) {
      rfmGraphData = _.filter(rfmGraphData, { churn_prediction: 1 });
    }
    const calc_total_customers = rfmGraphData.length;
    const calc_total_orders =
      rfmGraphData.length > 0 ? _.sumBy(rfmGraphData, "frequency") : 0;
    // const calc_days_since_last_order = _.meanBy(rfmGraphData, 'recency');
    const calc_orders_per_customer =
      rfmGraphData.length > 0
        ? _.sumBy(rfmGraphData, "frequency") / rfmGraphData.length
        : 0;
    const calc_rev_per_customer =
      rfmGraphData.length > 0
        ? _.sumBy(rfmGraphData, "monetory_value") / rfmGraphData.length
        : 0;
    const avg_hvcScore =
      rfmGraphData.length > 0 && hvcKey
        ? Math.round(_.sumBy(rfmGraphData, "hvc_score") / rfmGraphData.length)
        : 0;
    await dispatch({
      type: types.SET_APPLIED_FILTER_VALUES,
      recencyValue,
      frequencyValue,
      monetaryValue,
      aovValue,
      ppvValue,
      hvcScoreValue,
      segmentAValue,
      segmentBValue,
      segmentCValue,
    });
    await dispatch({
      type: types.CREATE_NEW_FITLER_OBJECT,
      recencyValue,
      frequencyValue,
      monetaryValue,
      aovValue,
      ppvValue,
      hvcScoreValue,
      segmentAValue,
      segmentBValue,
      segmentCValue,
      pred_repeat_buyer_2m,
      pred_churn,
      pred_single_repeat_buyer,
      calc_total_customers,
      calc_total_orders,
      calc_orders_per_customer,
      calc_rev_per_customer,
      avg_hvcScore,
    });
  };
}

export function SetRemarketPageInitialValues() {
  return async function (dispatch, getState) {
    const user_segment_data = getState().user_segment_reducer.users;
    const nonZeroData = _.reject(user_segment_data, function (o) {
      if (!Object.keys(o).includes("aov")) {
        o["aov"] = o.monetory_value / o.frequency;
      }
      return o.monetory_value < 0;
    });
    const recencyRangeValues = nonZeroData.map(function (data) {
      return data.recency;
    });
    const frequencyRangeValues = nonZeroData.map(function (data) {
      return data.frequency;
    });
    const monetaryRangeValues = nonZeroData.map(function (data) {
      return data.monetory_value;
    });
    const aovRangeValues = nonZeroData.map(function (data) {
      return data.aov;
    });
    const ppvRangeValues = nonZeroData.map(function (data) {
      return data.clv;
    });
    const hvcScoreRangeValues = nonZeroData.map(function (data) {
      if (data.hvc_score || data.hvc_score >= 0) {
        return data.hvc_score;
      }
      return null;
    });
    const clvKey =
      nonZeroData.length > 1
        ? Object.keys(nonZeroData[0]).includes("clv")
        : false;
    const hvcKey =
      nonZeroData.length > 1
        ? Object.keys(nonZeroData[0]).includes("hvc_score")
        : false;
    let allRecencyValue = [
      _.min(recencyRangeValues),
      _.max(recencyRangeValues),
    ];
    let allFrequencyValue = [
      _.min(frequencyRangeValues),
      _.max(frequencyRangeValues),
    ];
    let allMonetaryValue = [
      Math.floor(_.min(monetaryRangeValues)),
      Math.ceil(_.max(monetaryRangeValues)),
    ];
    let allAovValue = [
      Math.floor(_.min(aovRangeValues)),
      Math.ceil(_.max(aovRangeValues)),
    ];
    let allPpvValue = [
      Math.floor(_.min(ppvRangeValues)),
      Math.ceil(_.max(ppvRangeValues)),
    ];
    let allHvcScoreValue = [
      Math.floor(_.min(hvcScoreRangeValues)),
      Math.ceil(_.max(hvcScoreRangeValues)),
    ];
    dispatch({
      type: types.SET_REMARKET_PAGE_INITIAL_VALUES,
      nonZeroData,
      recencyRangeValues,
      frequencyRangeValues,
      monetaryRangeValues,
      aovRangeValues,
      ppvRangeValues,
      hvcScoreRangeValues,
      clvKey,
      hvcKey,
      allRecencyValue,
      allFrequencyValue,
      allMonetaryValue,
      allAovValue,
      allPpvValue,
      allHvcScoreValue,
    });
  };
}

export function setFilterValues(filterObj) {
  // return async function (dispatch) {
  // dispatch
  return {
    type: types.SET_FILTER_VALUES,
    filterObj,
  };
  // };
}

export function setRemarketListName(remarket_list_name) {
  return async function (dispatch) {
    await dispatch({
      type: types.SET_REMARKET_LIST_NAME,
      remarket_list_name,
    });
  };
}

export function setReportButtonValueApplied(reportButtonApplied) {
  return async function (dispatch) {
    await dispatch({
      type: types.SET_REPORT_BUTTON_APPLIED,
      reportButtonApplied,
    });
  };
}

export function report_button_value(buttonValue, filter_name) {
  return async function (dispatch) {
    await dispatch({
      type: types.SET_REPORT_BUTTON,
      buttonValue,
      filter_name,
    });
  };
}

export function reset_filter_value() {
  return async function (dispatch) {
    await dispatch({
      type: types.SET_RESET_VALUES_TO_FILTER,
    });
  };
}

export function month_compare(monthcompvalue) {
  return async function (dispatch) {
    dispatch({
      type: types.MONTH_COMPARE,
      monthcompvalue,
    });
  };
}

export function analytics_month_compare(analyticsMonthCompare) {
  return async function (dispatch) {
    dispatch({
      type: types.ANALYTICS_MONTH_COMPARE,
      analyticsMonthCompare,
    });
  };
}

export function retry_re_market_action(retryReMarket, retryReMarketSource) {
  return async function (dispatch) {
    dispatch({
      type: types.SET_RETRY_RE_MARKET,
      retryReMarket,
      retryReMarketSource,
    });
  };
}

export function product_tour_run(tourRunValue) {
  return async function (dispatch) {
    dispatch({
      type: types.PRODUCT_TOUR_RUN,
      tourRunValue,
    });
  };
}

export function product_tour_step_index(tourStepIndexValue) {
  return async function (dispatch) {
    dispatch({
      type: types.PRODUCT_TOUR_STEP_INDEX,
      tourStepIndexValue,
    });
  };
}

/*
export function month_trend_initial_dates() {
    return async function (dispatch, getState) {
        const overview_data = getState().overview_reducer.data
        const aGroup = _.find(overview_data, {'segment': 'a'})
        const aStart = moment(aGroup.data_end).subtract(6, 'days');
        const aEnd = aGroup.data_end;
        const aCompareStart = moment(aGroup.data_end).subtract(13, 'days');
        const aCompareEnd = moment(aGroup.data_end).subtract(7, 'days');
        const bGroup = _.find(overview_data, {'segment': 'b'})
        const bStart = moment(bGroup.data_end).subtract(6, 'days');
        const bEnd = bGroup.data_end;
        const bCompareStart = moment(bGroup.data_end).subtract(13, 'days');
        const bCompareEnd = moment(bGroup.data_end).subtract(7, 'days');
        const cGroup = _.find(overview_data, {'segment': 'c'})
        const cStart = moment(cGroup.data_end).subtract(6, 'days');
        const cEnd = cGroup.data_end;
        const cCompareStart = moment(cGroup.data_end).subtract(13, 'days');
        const cCompareEnd = moment(cGroup.data_end).subtract(7, 'days');
        // const dGroup = _.find(overview_data, {'segment': 'd'})
        // const dStart = moment(dGroup.data_end).subtract(6, 'days');
        // const dEnd = dGroup.data_end;
        // const dCompareStart = moment(dGroup.data_end).subtract(13, 'days');
        // const dCompareEnd = moment(dGroup.data_end).subtract(7, 'days');
        const allGroup = _.find(overview_data, {'segment': 'all'})
        const allStart = moment(allGroup.data_end).subtract(6, 'days');
        const allEnd = allGroup.data_end;
        const allCompareStart = moment(allGroup.data_end).subtract(13, 'days');
        const allCompareEnd = moment(allGroup.data_end).subtract(7, 'days');
        dispatch({
            type: types.SET_A_GROUP_DATES,
            aStart, aEnd, aCompareStart, aCompareEnd
        })
        dispatch({
            type: types.SET_B_GROUP_DATES,
            bStart, bEnd, bCompareStart, bCompareEnd
        })
        dispatch({
            type: types.SET_C_GROUP_DATES,
            cStart, cEnd, cCompareStart, cCompareEnd
        })/!*
        dispatch({
            type: types.SET_D_GROUP_DATES,
            dStart, dEnd, dCompareStart, dCompareEnd
        })*!/
        dispatch({
            type: types.SET_ALL_GROUP_DATES,
            allStart, allEnd, allCompareStart, allCompareEnd
        })
    }
}*/

export function a_initial_dates(duration) {
  return async function (dispatch, getState) {
    const overview_data = getState().overview_reducer.data;
    const aGroup = _.find(overview_data, { segment: "a" });
    const aStart = moment(aGroup.data_end).subtract(duration, "days");
    const aEnd = aGroup.data_end;
    const aCompareStart = moment(aGroup.data_end).subtract(
      duration * 2 + 1,
      "days"
    );
    const aCompareEnd = moment(aGroup.data_end).subtract(duration + 1, "days");
    dispatch({
      type: types.SET_A_GROUP_DATES,
      aStart,
      aEnd,
      aCompareStart,
      aCompareEnd,
    });
  };
}

export function b_initial_dates(duration) {
  return async function (dispatch, getState) {
    const overview_data = getState().overview_reducer.data;
    const bGroup = _.find(overview_data, { segment: "b" });
    const bStart = moment(bGroup.data_end).subtract(duration, "days");
    const bEnd = bGroup.data_end;
    const bCompareStart = moment(bGroup.data_end).subtract(
      duration * 2 + 1,
      "days"
    );
    const bCompareEnd = moment(bGroup.data_end).subtract(duration + 1, "days");
    dispatch({
      type: types.SET_B_GROUP_DATES,
      bStart,
      bEnd,
      bCompareStart,
      bCompareEnd,
    });
  };
}

export function c_initial_dates(duration) {
  return async function (dispatch, getState) {
    const overview_data = getState().overview_reducer.data;
    const cGroup = _.find(overview_data, { segment: "c" });
    const cStart = moment(cGroup.data_end).subtract(duration, "days");
    const cEnd = cGroup.data_end;
    const cCompareStart = moment(cGroup.data_end).subtract(
      duration * 2 + 1,
      "days"
    );
    const cCompareEnd = moment(cGroup.data_end).subtract(duration + 1, "days");
    dispatch({
      type: types.SET_C_GROUP_DATES,
      cStart,
      cEnd,
      cCompareStart,
      cCompareEnd,
    });
  };
}

export function all_initial_dates(duration) {
  return async function (dispatch, getState) {
    const overview_data = getState().overview_reducer.data;
    const allGroup = _.find(overview_data, { segment: "all" });
    const allStart = moment(allGroup.data_end).subtract(duration, "days");
    const allEnd = allGroup.data_end;
    const allCompareStart = moment(allGroup.data_end).subtract(
      duration * 2 + 1,
      "days"
    );
    const allCompareEnd = moment(allGroup.data_end).subtract(
      duration + 1,
      "days"
    );
    dispatch({
      type: types.SET_ALL_GROUP_DATES,
      allStart,
      allEnd,
      allCompareStart,
      allCompareEnd,
    });
  };
}

export function a_month_change(start, end, compStart, compEnd, takeCompare) {
  return async function (dispatch, getState) {
    const overview_data = getState().overview_reducer.data;
    const monthCompare = getState().application_states.monthCompare;
    const aGroup = _.find(overview_data, { segment: "a" });
    let duration;
    let calDuration;
    let aStart =
      moment(start).diff(aGroup.data_start, "days") >= 0
        ? start
        : new Date(aGroup.data_start);
    let aEnd = end;
    duration = moment([
      aEnd.getFullYear(),
      aEnd.getMonth(),
      aEnd.getDate(),
    ]).diff(
      moment([aStart.getFullYear(), aStart.getMonth(), aStart.getDate()]),
      "days"
    );
    let aCompareEnd, aCompareStart;
    aCompareEnd = !takeCompare ? moment(aStart).subtract(1, "days") : compEnd;
    aCompareStart = !takeCompare
      ? moment(aCompareEnd).subtract(duration, "days")
      : compStart;
    if (
      monthCompare &&
      !takeCompare &&
      moment(aCompareStart).diff(aGroup.data_start, "days") <= 0
    ) {
      if ((moment(aEnd).diff(aGroup.data_start, "days") + 1) % 2 === 0) {
        calDuration = (moment(aEnd).diff(aGroup.data_start, "days") + 1) / 2;
        aStart = moment(aEnd).subtract(calDuration - 1, "days");
        aCompareStart = aGroup.data_start;
        aCompareEnd = moment(aCompareStart).add(calDuration - 1, "days");
      } else {
        calDuration = (moment(aEnd).diff(aGroup.data_start, "days") + 2) / 2;
        aStart = moment(aEnd).subtract(calDuration - 1, "days");
        aCompareStart = aGroup.data_start;
        aCompareEnd = moment(aCompareStart).add(calDuration - 1, "days");
      }
    }

    dispatch({
      type: types.SET_A_GROUP_DATES,
      aStart,
      aEnd,
      aCompareStart,
      aCompareEnd,
    });
  };
}

export function b_month_change(start, end, compStart, compEnd, takeCompare) {
  return async function (dispatch, getState) {
    const overview_data = getState().overview_reducer.data;
    const monthCompare = getState().application_states.monthCompare;
    const bGroup = _.find(overview_data, { segment: "b" });
    let duration;
    let calDuration;
    let bStart =
      moment(start).diff(bGroup.data_start, "days") >= 0
        ? start
        : new Date(bGroup.data_start);
    let bEnd = end;
    duration = moment([
      bEnd.getFullYear(),
      bEnd.getMonth(),
      bEnd.getDate(),
    ]).diff(
      moment([bStart.getFullYear(), bStart.getMonth(), bStart.getDate()]),
      "days"
    );
    let bCompareEnd = !takeCompare
      ? moment(bStart).subtract(1, "days")
      : compEnd;
    let bCompareStart = !takeCompare
      ? moment(bCompareEnd).subtract(duration, "days")
      : compStart;
    if (
      monthCompare &&
      !takeCompare &&
      moment(bCompareStart).diff(bGroup.data_start, "days") <= 0
    ) {
      if ((moment(bEnd).diff(bGroup.data_start, "days") + 1) % 2 === 0) {
        calDuration = (moment(bEnd).diff(bGroup.data_start, "days") + 1) / 2;
        bStart = moment(bEnd).subtract(calDuration - 1, "days");
        bCompareStart = bGroup.data_start;
        bCompareEnd = moment(bCompareStart).add(calDuration - 1, "days");
      } else {
        calDuration = (moment(bEnd).diff(bGroup.data_start, "days") + 2) / 2;
        bStart = moment(bEnd).subtract(calDuration - 1, "days");
        bCompareStart = bGroup.data_start;
        bCompareEnd = moment(bCompareStart).add(calDuration - 1, "days");
      }
    }
    dispatch({
      type: types.SET_B_GROUP_DATES,
      bStart,
      bEnd,
      bCompareStart,
      bCompareEnd,
    });
  };
}

export function c_month_change(start, end, compStart, compEnd, takeCompare) {
  return async function (dispatch, getState) {
    const overview_data = getState().overview_reducer.data;
    const monthCompare = getState().application_states.monthCompare;
    const cGroup = _.find(overview_data, { segment: "c" });
    let duration;
    let calDuration;
    let cStart =
      moment(start).diff(cGroup.data_start, "days") >= 0
        ? start
        : new Date(cGroup.data_start);
    let cEnd = end;
    duration = moment([
      cEnd.getFullYear(),
      cEnd.getMonth(),
      cEnd.getDate(),
    ]).diff(
      moment([cStart.getFullYear(), cStart.getMonth(), cStart.getDate()]),
      "days"
    );
    let cCompareEnd = !takeCompare
      ? moment(cStart).subtract(1, "days")
      : compEnd;
    let cCompareStart = !takeCompare
      ? moment(cCompareEnd).subtract(duration, "days")
      : compStart;
    if (
      monthCompare &&
      !takeCompare &&
      moment(cCompareStart).diff(cGroup.data_start, "days") <= 0
    ) {
      if ((moment(cEnd).diff(cGroup.data_start, "days") + 1) % 2 === 0) {
        calDuration = (moment(cEnd).diff(cGroup.data_start, "days") + 1) / 2;
        cStart = moment(cEnd).subtract(calDuration - 1, "days");
        cCompareStart = cGroup.data_start;
        cCompareEnd = moment(cCompareStart).add(calDuration - 1, "days");
      } else {
        calDuration = (moment(cEnd).diff(cGroup.data_start, "days") + 2) / 2;
        cStart = moment(cEnd).subtract(calDuration - 1, "days");
        cCompareStart = cGroup.data_start;
        cCompareEnd = moment(cCompareStart).add(calDuration - 1, "days");
      }
    }
    dispatch({
      type: types.SET_C_GROUP_DATES,
      cStart,
      cEnd,
      cCompareStart,
      cCompareEnd,
    });
  };
}

export function google_analytics_month_change(
  start,
  end,
  compStart,
  compEnd,
  takeCompare
) {
  return async function (dispatch) {
    let duration;
    let googleAnalyticsStart = start;
    let googleAnalyticsEnd = end;
    duration = moment([
      new Date(googleAnalyticsEnd).getFullYear(),
      new Date(googleAnalyticsEnd).getMonth(),
      new Date(googleAnalyticsEnd).getDate(),
    ]).diff(
      moment([
        new Date(googleAnalyticsStart).getFullYear(),
        new Date(googleAnalyticsStart).getMonth(),
        new Date(googleAnalyticsStart).getDate(),
      ]),
      "days"
    );
    let googleAnalyticsCompareEnd = !takeCompare
      ? moment(googleAnalyticsStart).subtract(1, "days")
      : compEnd;
    let googleAnalyticsCompareStart = !takeCompare
      ? moment(googleAnalyticsCompareEnd).subtract(duration, "days")
      : compStart;
    dispatch({
      type: types.SET_GOOGLE_ANALYTICS_DATES,
      googleAnalyticsStart,
      googleAnalyticsEnd,
      googleAnalyticsCompareStart,
      googleAnalyticsCompareEnd,
    });
  };
}

export function redeal_analytics_month_change(
  start,
  end,
  compStart,
  compEnd,
  takeCompare
) {
  return async function (dispatch) {
    let duration;
    let redealAnalyticsStart = start;
    let redealAnalyticsEnd = end;
    duration = moment([
      new Date(redealAnalyticsEnd).getFullYear(),
      new Date(redealAnalyticsEnd).getMonth(),
      new Date(redealAnalyticsEnd).getDate(),
    ]).diff(
      moment([
        new Date(redealAnalyticsStart).getFullYear(),
        new Date(redealAnalyticsStart).getMonth(),
        new Date(redealAnalyticsStart).getDate(),
      ]),
      "days"
    );
    let redealAnalyticsCompareEnd = !takeCompare
      ? moment(redealAnalyticsStart).subtract(1, "days")
      : compEnd;
    let redealAnalyticsCompareStart = !takeCompare
      ? moment(redealAnalyticsCompareEnd).subtract(duration, "days")
      : compStart;
    dispatch({
      type: types.SET_REDEAL_ANALYTICS_DATES,
      redealAnalyticsStart,
      redealAnalyticsEnd,
      redealAnalyticsCompareStart,
      redealAnalyticsCompareEnd,
    });
  };
}

export function product_bundle(segment, season, region, regionType) {
  return async function (dispatch) {
    const url = `api/product-analytics/bundling`;
    const data = {};
    data["segment"] = segment;
    data["season"] = season;
    data["region"] = region
      ? regionType[0] === "US"
        ? region.label
        : region.code
      : null;
    dispatch({
      type: types.PRODUCT_BUNDLE_DATA_REQUEST,
    });
    try {
      if (!StoredSegmentData[url + segment + season + data.region]) {
        let response = await axios.post(url, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        });
        let resultData = await response.data;
        dispatch({
          type: types.PRODUCT_BUNDLE_DATA_SUCCESS,
          resultData,
        });
        if (Object.keys(StoredSegmentData).length <= 60) {
          StoredSegmentData[url + segment + season + data.region] = resultData;
        } else {
          delete StoredSegmentData[Object.keys(StoredSegmentData)[0]];
          StoredSegmentData[url + segment + season + data.region] = resultData;
        }
      } else {
        let resultData = await StoredSegmentData[
          url + segment + season + data.region
        ];
        dispatch({
          type: types.PRODUCT_BUNDLE_DATA_SUCCESS,
          resultData,
        });
      }
    } catch (e) {
      console.log("No Product Bundle Data ", e);
      let resultData;
      if (e.response) {
        resultData = e.response;
      }
      dispatch({
        type: types.PRODUCT_BUNDLE_DATA_FAILURE,
        resultData,
      });
    }
  };
}

// export function product_bundle(preFetch, compare, segment, start, end) {
//   return async function (dispatch, getState) {
//     const useDummyData = window.lxrOptions && window.lxrOptions.useDummyData;
//     const mainURL = `/api/customergroup/product_bundling/${segment}?start=${moment(
//       start
//     ).format("DD-MM-yyyy")}&end=${moment(end).format("DD-MM-yyyy")}`;
//     const url = useDummyData ? "/sampleOrderData.JSON" : mainURL;
//     if (url === "/sampleOrderData.JSON") {
//       dispatch({
//         type: types.PRODUCT_BUNDLE_DATA_REQUEST,
//       });
//       try {
//         let response = await fetch(url, {
//           method: "GET",
//           headers: {
//             Accept: "application/json",
//           },
//           credentials: "include",
//         });
//         let resultData = await response.json();
//         dispatch({
//           type: types.PRODUCT_BUNDLE_DATA_SUCCESS,
//           resultData,
//         });
//         // return data;
//       } catch (e) {
//         console.log("error in fetch= ", e);
//         dispatch({
//           type: types.PRODUCT_BUNDLE_DATA_FAILURE,
//         });
//       }
//     } else if (!compare) {
//       if (
//         moment(getState().application_states[segment + "Start"]).format() ===
//           moment(start).format() &&
//         moment(getState().application_states[segment + "End"]).format() ===
//           moment(end).format()
//       ) {
//         dispatch({
//           type: types.SEGMENT_ORDER_DATA_REQUESTED,
//         });
//         try {
//           if (!StoredSegmentData[url]) {
//             let response = await axios.get(url, {
//               headers: {
//                 Authorization: `Bearer ${localStorage.getItem("access")}`,
//               },
//             });
//             let resultData = await response.data;
//             if (!preFetch) {
//               dispatch({
//                 type: types.SEGMENT_ORDER_DATA_RECEIVED,
//                 resultData,
//               });
//             }
//             if (Object.keys(StoredSegmentData).length <= 60) {
//               StoredSegmentData[url] = resultData;
//             } else {
//               delete StoredSegmentData[Object.keys(StoredSegmentData)[0]];
//               StoredSegmentData[url] = resultData;
//             }
//           } else if (!preFetch) {
//             let resultData = await StoredSegmentData[url];
//             dispatch({
//               type: types.SEGMENT_ORDER_DATA_RECEIVED,
//               resultData,
//             });
//           }
//           // return data;
//         } catch (e) {
//           console.log("error in fetch= ", e);
//           dispatch({
//             type: types.SEGMENT_ORDER_DATA_FAILED,
//           });
//         }
//       }
//     } else {
//       if (
//         moment(
//           getState().application_states[segment + "CompareStart"]
//         ).format() === moment(start).format() &&
//         moment(
//           getState().application_states[segment + "CompareEnd"]
//         ).format() === moment(end).format()
//       ) {
//         dispatch({
//           type: types.SEGMENT_COMPARE_ORDER_DATA_REQUESTED,
//         });
//         try {
//           if (!StoredSegmentData[url]) {
//             let response = await axios.get(url, {
//               headers: {
//                 Authorization: `Bearer ${localStorage.getItem("access")}`,
//               },
//             });
//             let resultData = await response.data;
//             if (!preFetch) {
//               dispatch({
//                 type: types.SEGMENT_COMPARE_ORDER_DATA_RECEIVED,
//                 resultData,
//               });
//             }
//             if (Object.keys(StoredSegmentData).length <= 60) {
//               StoredSegmentData[url] = resultData;
//             } else {
//               delete StoredSegmentData[Object.keys(StoredSegmentData)[0]];
//               StoredSegmentData[url] = resultData;
//             }
//           } else if (!preFetch) {
//             let resultData = await StoredSegmentData[url];
//             dispatch({
//               type: types.SEGMENT_COMPARE_ORDER_DATA_RECEIVED,
//               resultData,
//             });
//           }
//           // return data;
//         } catch (e) {
//           console.log("error in fetch= ", e);
//           dispatch({
//             type: types.SEGMENT_COMPARE_ORDER_DATA_FAILED,
//           });
//         }
//       }
//     }
//   };
// }

export function all_month_change(start, end, compStart, compEnd, takeCompare) {
  return async function (dispatch, getState) {
    const overview_data = getState().overview_reducer.data;
    const monthCompare = getState().application_states.monthCompare;
    const allGroup = _.find(overview_data, { segment: "all" });
    let duration;
    let calDuration;
    let allStart =
      moment(start).diff(allGroup.data_start, "days") >= 0
        ? start
        : new Date(allGroup.data_start);
    let allEnd = end;
    // duration = moment(allEnd).diff(allStart, 'days')
    duration = moment([
      allEnd.getFullYear(),
      allEnd.getMonth(),
      allEnd.getDate(),
    ]).diff(
      moment([allStart.getFullYear(), allStart.getMonth(), allStart.getDate()]),
      "days"
    );
    let allCompareEnd = !takeCompare
      ? moment(allStart).subtract(1, "days")
      : compEnd;
    let allCompareStart = !takeCompare
      ? moment(allCompareEnd).subtract(duration, "days")
      : moment(compStart);
    if (
      monthCompare &&
      !takeCompare &&
      moment(allCompareStart).diff(allGroup.data_start, "days") <= 0
    ) {
      if ((moment(allEnd).diff(allGroup.data_start, "days") + 1) % 2 === 0) {
        calDuration =
          (moment(allEnd).diff(allGroup.data_start, "days") + 1) / 2;
        allStart = moment(allEnd).subtract(calDuration - 1, "days");
        allCompareStart = allGroup.data_start;
        allCompareEnd = moment(allCompareStart).add(calDuration - 1, "days");
      } else {
        calDuration =
          (moment(allEnd).diff(allGroup.data_start, "days") + 2) / 2;
        allStart = moment(allEnd).subtract(calDuration - 1, "days");
        allCompareStart = allGroup.data_start;
        allCompareEnd = moment(allCompareStart).add(calDuration - 1, "days");
      }
    }
    dispatch({
      type: types.SET_ALL_GROUP_DATES,
      allStart,
      allEnd,
      allCompareStart,
      allCompareEnd,
    });
  };
}

export function overview_data() {
  return async function (dispatch) {
    const useDummyData = window.lxrOptions && window.lxrOptions.useDummyData;
    const url = useDummyData
      ? "/sampleOverviewData.JSON"
      : "/api/customergroup/overview/";
    // const url = true ? "/3groupOverviewData.JSON" : "/api/customergroup/overview/";
    if (url === "/sampleOverviewData.JSON") {
      dispatch({
        type: types.OVERVIEW_DATA_REQUESTED,
      });
      try {
        let response = await fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
          },
          credentials: "include",
        });
        let data = await response.json();
        dispatch({
          type: types.OVERVIEW_DATA_RECEIVED,
          data,
        });
        return data;
      } catch (e) {
        console.log("error in fetch= ", e);
        dispatch({
          type: types.OVERVIEW_DATA_FAILED,
        });
      }
    } else {
      dispatch({
        type: types.OVERVIEW_DATA_REQUESTED,
      });
      try {
        let response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        });
        let data = await response.data;
        dispatch({
          type: types.OVERVIEW_DATA_RECEIVED,
          data,
        });
        // return data;
      } catch (e) {
        console.log("error in fetch= ", e);
        dispatch({
          type: types.OVERVIEW_DATA_FAILED,
        });
      }
    }
  };
}

export function customerAnalyticsSegmentName(payload) {
  return async function (dispatch) {
    dispatch({
      type: types.SET_CUSTOMER_ANALYTICS_SEGMENT_NAME,
      payload,
    });
  };
}

export function SegmentOrderData(preFetch, compare, segment, start, end) {
  return async function (dispatch, getState) {
    const useDummyData = window.lxrOptions && window.lxrOptions.useDummyData;
    const mainURL = `api/customergroup/orders/date-range/${segment}?start=${moment(
      start
    ).format("DD-MM-yyyy")}&end=${moment(end).format("DD-MM-yyyy")}`;
    const url = useDummyData ? "/sampleOrderData.JSON" : mainURL;
    if (url === "/sampleOrderData.JSON") {
      dispatch({
        type: types.SEGMENT_ORDER_DATA_REQUESTED,
      });
      try {
        let response = await fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
          },
          credentials: "include",
        });
        let resultData = await response.json();
        dispatch({
          type: types.SEGMENT_ORDER_DATA_RECEIVED,
          resultData,
        });
        // return data;
      } catch (e) {
        console.log("error in fetch= ", e);
        dispatch({
          type: types.SEGMENT_ORDER_DATA_FAILED,
        });
      }
    } else if (!compare) {
      if (
        moment(getState().application_states[segment + "Start"]).format() ===
        moment(start).format() &&
        moment(getState().application_states[segment + "End"]).format() ===
        moment(end).format()
      ) {
        dispatch({
          type: types.SEGMENT_ORDER_DATA_REQUESTED,
        });
        try {
          if (!StoredSegmentData[url]) {
            let response = await axios.get(url, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access")}`,
              },
            });
            let resultData = await response.data;
            if (!preFetch) {
              dispatch({
                type: types.SEGMENT_ORDER_DATA_RECEIVED,
                resultData,
              });
            }
            if (Object.keys(StoredSegmentData).length <= 60) {
              StoredSegmentData[url] = resultData;
            } else {
              delete StoredSegmentData[Object.keys(StoredSegmentData)[0]];
              StoredSegmentData[url] = resultData;
            }
          } else if (!preFetch) {
            let resultData = await StoredSegmentData[url];
            dispatch({
              type: types.SEGMENT_ORDER_DATA_RECEIVED,
              resultData,
            });
          }
          // return data;
        } catch (e) {
          console.log("error in fetch= ", e);
          dispatch({
            type: types.SEGMENT_ORDER_DATA_FAILED,
          });
        }
      }
    } else {
      if (
        moment(
          getState().application_states[segment + "CompareStart"]
        ).format() === moment(start).format() &&
        moment(
          getState().application_states[segment + "CompareEnd"]
        ).format() === moment(end).format()
      ) {
        dispatch({
          type: types.SEGMENT_COMPARE_ORDER_DATA_REQUESTED,
        });
        try {
          if (!StoredSegmentData[url]) {
            let response = await axios.get(url, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access")}`,
              },
            });
            let resultData = await response.data;
            if (!preFetch) {
              dispatch({
                type: types.SEGMENT_COMPARE_ORDER_DATA_RECEIVED,
                resultData,
              });
            }
            if (Object.keys(StoredSegmentData).length <= 60) {
              StoredSegmentData[url] = resultData;
            } else {
              delete StoredSegmentData[Object.keys(StoredSegmentData)[0]];
              StoredSegmentData[url] = resultData;
            }
          } else if (!preFetch) {
            let resultData = await StoredSegmentData[url];
            dispatch({
              type: types.SEGMENT_COMPARE_ORDER_DATA_RECEIVED,
              resultData,
            });
          }
          // return data;
        } catch (e) {
          console.log("error in fetch= ", e);
          dispatch({
            type: types.SEGMENT_COMPARE_ORDER_DATA_FAILED,
          });
        }
      }
    }
  };
}

export function SegmentPathData(preFetch, compare, segment, top, start, end) {
  return async function (dispatch, getState) {
    const useDummyData = window.lxrOptions && window.lxrOptions.useDummyData;
    const mainURL = top
      ? `api/customergroup/conv-paths/date-range/${segment}/${top}/?start=${moment(
        start
      ).format("DD-MM-yyyy")}&end=${moment(end).format("DD-MM-yyyy")}`
      : `api/customergroup/conv-paths/date-range/${segment}/?start=${moment(
        start
      ).format("DD-MM-yyyy")}&end=${moment(end).format("DD-MM-yyyy")}`;
    const url = useDummyData ? "/segment_path_data.JSON" : mainURL;
    if (url === "/segment_path_data.JSON") {
      dispatch({
        type: types.SEGMENT_PATH_DATA_REQUESTED,
      });
      try {
        let response = await fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
          },
          credentials: "include",
        });
        let resultData = await response.json();
        dispatch({
          type: types.SEGMENT_PATH_DATA_RECEIVED,
          resultData,
        });
      } catch (e) {
        console.log("error in fetch= ", e);
        dispatch({
          type: types.SEGMENT_PATH_DATA_FAILED,
        });
      }
    } else if (!compare) {
      if (
        moment(getState().application_states[segment + "Start"]).format() ===
        moment(start).format() &&
        moment(getState().application_states[segment + "End"]).format() ===
        moment(end).format()
      ) {
        dispatch({
          type: types.SEGMENT_PATH_DATA_REQUESTED,
        });
        try {
          if (!StoredSegmentData[url]) {
            let response = await axios.get(url, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access")}`,
              },
            });
            let resultData = await response.data;

            if (!preFetch) {
              dispatch({
                type: types.SEGMENT_PATH_DATA_RECEIVED,
                resultData,
              });
            }
            if (Object.keys(StoredSegmentData).length <= 60) {
              StoredSegmentData[url] = resultData;
            } else {
              delete StoredSegmentData[Object.keys(StoredSegmentData)[0]];
              StoredSegmentData[url] = resultData;
            }
          } else if (!preFetch) {
            let resultData = await StoredSegmentData[url];
            dispatch({
              type: types.SEGMENT_PATH_DATA_RECEIVED,
              resultData,
            });
          }
          // return data;
        } catch (e) {
          console.log("error in fetch= ", e);
          dispatch({
            type: types.SEGMENT_PATH_DATA_FAILED,
          });
        }
      }
    } else {
      if (
        moment(
          getState().application_states[segment + "CompareStart"]
        ).format() === moment(start).format() &&
        moment(
          getState().application_states[segment + "CompareEnd"]
        ).format() === moment(end).format()
      ) {
        dispatch({
          type: types.SEGMENT_COMPARE_PATH_DATA_REQUESTED,
        });
        try {
          if (!StoredSegmentData[url]) {
            let response = await axios.get(url, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access")}`,
              },
            });
            let resultData = await response.data;
            if (!preFetch) {
              dispatch({
                type: types.SEGMENT_COMPARE_PATH_DATA_RECEIVED,
                resultData,
              });
            }
            if (Object.keys(StoredSegmentData).length <= 60) {
              StoredSegmentData[url] = resultData;
            } else {
              delete StoredSegmentData[Object.keys(StoredSegmentData)[0]];
              StoredSegmentData[url] = resultData;
            }
          } else if (!preFetch) {
            let resultData = await StoredSegmentData[url];
            dispatch({
              type: types.SEGMENT_COMPARE_PATH_DATA_RECEIVED,
              resultData,
            });
          }
          // return data;
        } catch (e) {
          console.log("error in fetch= ", e);
          dispatch({
            type: types.SEGMENT_COMPARE_PATH_DATA_FAILED,
          });
        }
      }
    }
  };
}

export function SegmentProductData(
  preFetch,
  compare,
  segment,
  top,
  start,
  end
) {
  return async function (dispatch, getState) {
    const useDummyData = window.lxrOptions && window.lxrOptions.useDummyData;
    const mainURL = top
      ? `api/customergroup/products/date-range/${segment}/${top}/?start=${moment(
        start
      ).format("DD-MM-yyyy")}&end=${moment(end).format("DD-MM-yyyy")}`
      : `api/customergroup/products/date-range/${segment}/?start=${moment(
        start
      ).format("DD-MM-yyyy")}&end=${moment(end).format("DD-MM-yyyy")}`;
    const url = useDummyData ? "/segment_product_data.JSON" : mainURL;
    if (url === "/segment_product_data.JSON") {
      dispatch({
        type: types.SEGMENT_PRODUCT_DATA_REQUESTED,
      });
      try {
        let response = await fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
          },
          credentials: "include",
        });
        let resultData = await response.json();
        dispatch({
          type: types.SEGMENT_PRODUCT_DATA_RECEIVED,
          resultData,
        });
      } catch (e) {
        console.log("error in fetch= ", e);
        dispatch({
          type: types.SEGMENT_PRODUCT_DATA_FAILED,
        });
      }
    } else if (!compare) {
      if (
        moment(getState().application_states[segment + "Start"]).format() ===
        moment(start).format() &&
        moment(getState().application_states[segment + "End"]).format() ===
        moment(end).format()
      ) {
        dispatch({
          type: types.SEGMENT_PRODUCT_DATA_REQUESTED,
        });
        try {
          if (!StoredSegmentData[url]) {
            let response = await axios.get(url, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access")}`,
              },
            });
            let resultData = await response.data;
            if (!preFetch) {
              dispatch({
                type: types.SEGMENT_PRODUCT_DATA_RECEIVED,
                resultData,
              });
            }
            if (Object.keys(StoredSegmentData).length <= 60) {
              StoredSegmentData[url] = resultData;
            } else {
              delete StoredSegmentData[Object.keys(StoredSegmentData)[0]];
              StoredSegmentData[url] = resultData;
            }
          } else if (!preFetch) {
            let resultData = await StoredSegmentData[url];
            dispatch({
              type: types.SEGMENT_PRODUCT_DATA_RECEIVED,
              resultData,
            });
          }
          // return data;
        } catch (e) {
          console.log("error in fetch= ", e);
          dispatch({
            type: types.SEGMENT_PRODUCT_DATA_FAILED,
          });
        }
      }
    } else {
      if (
        moment(
          getState().application_states[segment + "CompareStart"]
        ).format() === moment(start).format() &&
        moment(
          getState().application_states[segment + "CompareEnd"]
        ).format() === moment(end).format()
      ) {
        dispatch({
          type: types.SEGMENT_COMPARE_PRODUCT_DATA_REQUESTED,
        });
        try {
          if (!StoredSegmentData[url]) {
            let response = await axios.get(url, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access")}`,
              },
            });
            let resultData = await response.data;
            if (!preFetch) {
              dispatch({
                type: types.SEGMENT_COMPARE_PRODUCT_DATA_RECEIVED,
                resultData,
              });
            }
            if (Object.keys(StoredSegmentData).length <= 60) {
              StoredSegmentData[url] = resultData;
            } else {
              delete StoredSegmentData[Object.keys(StoredSegmentData)[0]];
              StoredSegmentData[url] = resultData;
            }
          } else if (!preFetch) {
            let resultData = await StoredSegmentData[url];
            dispatch({
              type: types.SEGMENT_COMPARE_PRODUCT_DATA_RECEIVED,
              resultData,
            });
          }
          // return data;
        } catch (e) {
          console.log("error in fetch= ", e);
          dispatch({
            type: types.SEGMENT_COMPARE_PRODUCT_DATA_FAILED,
          });
        }
      }
    }
  };
}

export function PopulationData() {
  return async function (dispatch) {
    const url = "/api/us-population/";
    dispatch({
      type: types.POPULATION_DATA_REQUESTED,
    });
    try {
      let response = await axios.get(url, {
        headers: { Authorization: `Bearer ${localStorage.getItem("access")}` },
      });
      let resultData = await response.data;
      dispatch({
        type: types.POPULATION_DATA_RECEIVED,
        resultData,
      });
    } catch (e) {
      console.log("error in population fetch ", e);
      dispatch({
        type: types.POPULATION_DATA_FAILED,
      });
    }
  };
}

export function UserSegmentData() {
  return async function (dispatch) {
    // const url = "/json_files/userSegmentData.JSON";
    // const url = '/3groupUserSegmentData.JSON';
    // const url = '/userSegmentHVCScoredata.JSON';

    const url = "api/customergroup/user_segments/";

    const config = {
      method: "get",
      url,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    };

    dispatch({
      type: types.USER_SEGMENT_DATA_REQUEST,
    });
    try {
      const response = await axios(config);
      let userData = await response.data;
      dispatch({
        type: types.USER_SEGMENT_DATA_SUCCESS,
        userData,
      });
    } catch (e) {
      console.log("error in fetch= ", e);
      dispatch({
        type: types.USER_SEGMENT_DATA_FAILURE,
      });
    }
  };
}

export function NotificationData() {
  return async function (dispatch) {
    const url = "api/notifications/";
    // const url = '/notificationData.JSON';
    dispatch({
      type: types.NOTIFICATION_DATA_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let notificationData = await response.data;
      dispatch({
        type: types.NOTIFICATION_DATA_SUCCESS,
        notificationData,
      });
    } catch (e) {
      console.log("error in fetch= ", e);
      dispatch({
        type: types.NOTIFICATION_DATA_FAILURE,
      });
    }
  };
}

export function NotificationDelete(notifId) {
  return async function (dispatch) {
    const url = `api/notification-delete/?id=${notifId}`;
    // const url = '/notificationData.JSON';
    dispatch({
      type: types.NOTIFICATION_DELETE_REQUEST,
    });
    try {
      let response = await axios.post(url, "", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      // console.log("response", response)
      dispatch({
        type: types.NOTIFICATION_DELETE_SUCCESS,
        response,
      });
    } catch (e) {
      console.log("error in fetch= ", e);
      dispatch({
        type: types.NOTIFICATION_DELETE_FAILURE,
      });
    }
  };
}

export function set_all_group_filter(filterName, filterValue) {
  return async function (dispatch, getState) {
    let allFilter =
      filterName !== "clear"
        ? {
          ...getState().filterStates.filterAll,
          [filterName]: filterValue,
        }
        : filterValue
          ? _.omit(getState().filterStates.filterAll, [filterValue])
          : {};
    dispatch({
      type: types.SET_ALL_FILTER,
      allFilter,
    });
  };
}

export function set_a_group_filter(filterName, filterValue) {
  return async function (dispatch, getState) {
    let aFilter =
      filterName !== "clear"
        ? {
          ...getState().filterStates.filterA,
          [filterName]: filterValue,
        }
        : filterValue
          ? _.omit(getState().filterStates.filterA, [filterValue])
          : {};
    dispatch({
      type: types.SET_A_FILTER,
      aFilter,
    });
  };
}

export function set_b_group_filter(filterName, filterValue) {
  return async function (dispatch, getState) {
    let bFilter =
      filterName !== "clear"
        ? {
          ...getState().filterStates.filterB,
          [filterName]: filterValue,
        }
        : filterValue
          ? _.omit(getState().filterStates.filterB, [filterValue])
          : {};
    dispatch({
      type: types.SET_B_FILTER,
      bFilter,
    });
  };
}

export function set_c_group_filter(filterName, filterValue) {
  return async function (dispatch, getState) {
    let cFilter =
      filterName !== "clear"
        ? {
          ...getState().filterStates.filterC,
          [filterName]: filterValue,
        }
        : filterValue
          ? _.omit(getState().filterStates.filterC, [filterValue])
          : {};
    dispatch({
      type: types.SET_C_FILTER,
      cFilter,
    });
  };
}

export function get_auth_url(source, redirectURL = undefined) {
  return async function (dispatch) {
    redirectURL = window.location.port
      ? "http://" +
      window.location.hostname +
      ":" +
      window.location.port +
      `/?page=${settingConnectionsURL}`
      : "https://" + window.location.hostname + `/?page=${settingConnectionsURL}`;
    // console.log("redirectURL", redirectURL)
    const url = redirectURL
      ? `api/user_settings/get-auth-url/?source=${source}&redirect_url=${encodeURIComponent(
        redirectURL
      )}`
      : `api/user_settings/get-auth-url/?source=${source}`;
    dispatch({
      type: types.AUTH_URL_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let authData = await response.data;
      dispatch({
        type: types.AUTH_URL_RECEIVED,
        authData,
      });
    } catch (e) {
      console.log("error in fetch= ", e);
      dispatch({
        type: types.AUTH_URL_FAILED,
      });
    }
  };
}

export function get_auth_url_fb(redirectURL = undefined, reauth) {
  return async function (dispatch) {
    redirectURL = reauth
      ? window.location.port
        ? "http://" +
        window.location.hostname +
        ":" +
        window.location.port +
        // "/#/connections/"
        `/?page=${settingConnectionsURL}&reauth=True`
        : "https://" +
        window.location.hostname +
        `/?page=${settingConnectionsURL}&reauth=True`
      : window.location.port
        ? "http://" +
        window.location.hostname +
        ":" +
        window.location.port +
        // "/#/connections/"
        `/?page=${settingConnectionsURL}`
        : "https://" + window.location.hostname + `/?page=${settingConnectionsURL}`;
    // console.log("redirectURL", redirectURL)
    const url = `api/user_settings/facebook/get-auth-url/`;
    dispatch({
      type: types.FB_AUTH_URL_REQUEST,
    });
    try {
      let response = await axios.post(
        url,
        { redirect_url: redirectURL },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
            "Content-Type": `application/json`,
            "X-CSRFToken": csrftoken,
          },
        }
      );
      let authData = await response.data;
      dispatch({
        type: types.FB_AUTH_URL_RECEIVED,
        authData,
      });
    } catch (e) {
      console.log("error in fetch= ", e);
      dispatch({
        type: types.FB_AUTH_URL_FAILED,
      });
    }
  };
}

export function send_auth_var_ga(state, code, redirectURL, currentURl) {
  return async function (dispatch) {
    localStorage.removeItem("source");
    localStorage.removeItem("state");
    localStorage.removeItem("code");
    localStorage.removeItem("redirectURL");
    localStorage.removeItem("currentURl");
    const url = "api/user_settings/ga/create_token/";
    dispatch({
      type: types.GA_AUTH_VAR_SENT_REQUEST,
    });
    try {
      let response = await axios.post(
        url,
        {
          state: state,
          code: code,
          redirect_url: redirectURL,
          current_url: currentURl,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        }
      );
      let authResponse = await response.data;
      // console.log("action response", authResponse)
      dispatch({
        type: types.GA_AUTH_VAR_SENT_SUCCESS,
        authResponse,
      });
    } catch (e) {
      console.log("error in auth sending= ", e);
      dispatch({
        type: types.GA_AUTH_VAR_SENT_FAILED,
      });
    }
  };
}

export const resetAuthVarData = () => ({
  type: types.GA_AUTH_VAR_RESET_STATE,
});

export function send_auth_var_fb(state, code, redirectURL, currentURl) {
  return async function (dispatch) {
    localStorage.removeItem("source");
    localStorage.removeItem("state");
    localStorage.removeItem("code");
    localStorage.removeItem("redirectURL");
    localStorage.removeItem("currentURl");
    const url = "api/user_settings/facebook/get-access-token/";
    dispatch({
      type: types.FB_AUTH_VAR_SENT_REQUEST,
    });
    try {
      let response = await axios.post(
        url,
        {
          redirect_url: currentURl,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        }
      );
      let authResponse = await response.data;
      // console.log("action response", authResponse)
      dispatch({
        type: types.FB_AUTH_VAR_SENT_SUCCESS,
        authResponse,
      });
    } catch (e) {
      console.log("error in auth sending= ", e);
      dispatch({
        type: types.FB_AUTH_VAR_SENT_FAILED,
      });
    }
  };
}

export function send_selected_gaId(gaId, ga_name, ga_mcc) {
  return async function (dispatch) {
    const url = "api/user_settings/ga/set_client_id/";
    dispatch({
      type: types.GAID_SENT_REQUEST,
    });
    try {
      let response = await axios.post(
        url,
        {
          ga_client_id: gaId,
          ga_acc_name: ga_name,
          ga_login_id: ga_mcc,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        }
      );
      let responseData = await response.data;
      // console.log("action response", authResponse)
      dispatch({
        type: types.GAID_SENT_SUCCESS,
        responseData,
      });
    } catch (e) {
      console.log("error in gaid sending= ", e);
      dispatch({
        type: types.GAID_SENT_FAILED,
      });
    }
  };
}

export function send_selected_fbId(fbId, fbAccName) {
  return async function (dispatch) {
    const url = "api/user_settings/facebook/set_client_id/";
    dispatch({
      type: types.FBID_SENT_REQUEST,
    });
    try {
      let response = await axios.post(
        url,
        {
          id: fbId,
          acc_name: fbAccName,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        }
      );
      let responseData = await response.data;
      // console.log("action response", authResponse)
      dispatch({
        type: types.FBID_SENT_SUCCESS,
        responseData,
      });
    } catch (e) {
      console.log("error in fbid sending= ", e);
      dispatch({
        type: types.FBID_SENT_FAILED,
      });
    }
  };
}

export function send_redeal_siteid(id) {
  return async function (dispatch) {
    const url = "api/user_settings/redeal/set_site_id/";
    dispatch({
      type: types.REDEALID_SENT_REQUEST,
    });
    try {
      let response = await axios.post(
        url,
        {
          redeal_site_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        }
      );
      let responseData = await response.data;
      // console.log("action response", authResponse)
      dispatch({
        type: types.REDEALID_SENT_SUCCESS,
        responseData,
      });
    } catch (e) {
      console.log("error in redeal id sending= ", e);
      dispatch({
        type: types.REDEALID_SENT_FAILED,
      });
    }
  };
}

export function terms_of_use(terms_accepted, first_name, last_name, email) {
  return async function (dispatch) {
    const url = "api/terms-accepted/";
    dispatch({
      type: types.TERMS_ACCEPTED_REQUEST,
    });
    try {
      let response = await axios.post(
        url,
        {
          terms_accepted: terms_accepted,
          first_name: first_name,
          last_name: last_name,
          email: email,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        }
      );
      let responseData = await response.data;
      // console.log("action response", authResponse)
      dispatch({
        type: types.TERMS_ACCEPTED_SUCCESS,
        responseData,
      });
    } catch (e) {
      console.log("error in gaid sending= ", e);
      dispatch({
        type: types.TERMS_ACCEPTED_FAILED,
      });
    }
  };
}

export function product_tour_action(tour_action) {
  return async function (dispatch) {
    const url = "api/product-tour-action/";
    dispatch({
      type: types.PRODUCT_TOUR_ACTION_REQUEST,
    });
    try {
      let response = await axios.post(
        url,
        {
          tour_action: tour_action,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        }
      );
      let responseData = await response.data;
      dispatch({
        type: types.PRODUCT_TOUR_ACTION_SUCCESS,
        responseData,
      });
    } catch (e) {
      console.log("error in tour action sending= ", e);
      dispatch({
        type: types.PRODUCT_TOUR_ACTION_FAILED,
      });
    }
  };
}

export function clearPushAudienceStatus() {
  return async function (dispatch) {
    let responseData = { result: [] };
    dispatch({
      type: types.PUSH_AUDIENCE_SUCCESS,
      responseData,
    });
  };
}

export function resetPushAudienceReducer() {
  return async function (dispatch) {
    dispatch({
      type: types.PUSH_AUDIENCE_RESET,
    });
  };
}

export function resetUserSegmentReducer() {
  return async function (dispatch) {
    dispatch({
      type: types.USER_SEGMENT_DATA_RESET,
    });
  };
}

export function clearDeleteCustomFilter() {
  return async function (dispatch) {
    let responseData = { status: "" };
    dispatch({
      type: types.CLEAR_DELETE_FILTER,
      responseData,
    });
  };
}

export function clearAudienceCustomFilter() {
  return async function (dispatch) {
    let responseData = { status: "" };
    dispatch({
      type: types.CLEAR_AUDIENCES_FILTER,
      responseData,
    });
  };
}

export function clearSaveCustomerFilterStatus() {
  return async function (dispatch) {
    let responseData = { status: "" };
    dispatch({
      type: types.CLEAR_CUSTOM_FILTER,
      responseData,
    });
  };
}

export function clearGPTContent() {
  return async function (dispatch) {
    let responseData = { data: [], status: "", message: "" };
    dispatch({
      type: types.GENERATE_CONTENT_SUCCESS,
      responseData,
    });
  };
}

export function clearGPTMoreContent() {
  return async function (dispatch) {
    let responseData = { data: [], status: "", message: "" };
    dispatch({
      type: types.GENERATE_MORE_CONTENT_SUCCESS,
      responseData,
    });
  };
}

export function clearSignupStatus() {
  return async function (dispatch) {
    let responseData = { status: "", message: "", data: [] };
    dispatch({
      type: types.SIGN_UP_CLEAR_STATE,
      responseData,
    });
  };
}

export function clearForgotPasswordStatus() {
  return async function (dispatch) {
    let responseData = { status: "", message: "" };
    dispatch({
      type: types.FORGOT_PASSWORD_CLEAR_STATE,
      responseData,
    });
  };
}

export function clearLoginStatus() {
  return async function (dispatch) {
    // let responseData = {status:'',message:''};
    dispatch({
      type: types.LOGIN_CLEAR_STATE,
      // responseData,
    });
  };
}

export function clearChangePasswordStatus() {
  return async function (dispatch) {
    // let responseData = {message:''};
    dispatch({
      type: types.PASSWORD_CLEAR_STATE,
      // responseData,
    });
  };
}

export function clearWebsiteDetailsStatus() {
  return async function (dispatch) {
    let responseData = { status: "", message: "" };
    dispatch({
      type: types.WEBSITE_DETAILS_SUCCESS,
      responseData,
    });
  };
}

export function clearUpdateReplaceAudienceStatus() {
  return async function (dispatch) {
    let responseData = { result: [] };
    dispatch({
      type: types.UPDATE_REPLACE_SUCCESS,
      responseData,
    });
  };
}

export function clearPushAudienceFBStatus() {
  return async function (dispatch) {
    let responseData = { result: [] };
    dispatch({
      type: types.FB_PUSH_AUDIENCE_SUCCESS,
      responseData,
    });
  };
}

export function clearUpdateReplaceAudienceFBStatus() {
  return async function (dispatch) {
    let responseData = { result: [] };
    dispatch({
      type: types.FB_UPDATE_REPLACE_SUCCESS,
      responseData,
    });
  };
}

export function clearEditAudienceStatus() {
  return async function (dispatch) {
    let responseData = { success: "" };
    dispatch({
      type: types.EDIT_AUDIENCE_SUCCESS,
      responseData,
    });
  };
}

export function clearFBClientIds() {
  return async function (dispatch) {
    let authResponse = { account_list: [] };
    dispatch({
      type: types.FB_AUTH_VAR_SENT_SUCCESS,
      authResponse,
    });
  };
}

export function clearContactUsDetails() {
  return async function (dispatch) {
    let resultData = { status: "", message: "" };
    dispatch({
      type: types.CONTACTUS_FORM_CLEAR_STATE,
      resultData,
    });
  };
}

/*export function push_audiences_param(
    updateReplace,
    updateReplacePeriod,
    sources,
    recency,
    frequency,
    monetary,
    ppv,
    aov,
    hvcScore,
    allRecencyValue,
    allFrequencyValue,
    allMonetaryValue,
    allPpvValue,
    allAovValue,
    allHvcScoreValue,
    segment,
    customerURL,
    audience_name = "lxr_audience",
) {
    return async function (dispatch, getState) {
        let arraySources = [];
        arraySources.push(sources);
        let dataJson = {};
        if (Boolean(updateReplace)) {
            dataJson["update_replace"] = updateReplace;
            dataJson["update_replace_period"] = updateReplacePeriod;
        }
        dataJson["segment"] = segment;
        dataJson["sources"] = arraySources;
        dataJson["audience_name"] = audience_name;
        let pushParam = getState().application_states.pushParam;
        dataJson["preDefined"] = getState().application_states.pushPriorityParam;
        if (pushParam === "custom") {
            if (recency[0] !== allRecencyValue[0]) {
                dataJson["minRecency"] = recency[0];
            }
            if (recency[1] !== allRecencyValue[1]) {
                dataJson["maxRecency"] = recency[1];
            }
            if (frequency[0] !== allFrequencyValue[0]) {
                dataJson["minFrequency"] = frequency[0];
            }
            if (frequency[1] !== allFrequencyValue[1]) {
                dataJson["maxFrequency"] = frequency[1];
            }
            if (monetary[0] !== allMonetaryValue[0]) {
                dataJson["minMonetary"] = monetary[0];
            }
            if (monetary[1] !== allMonetaryValue[1]) {
                dataJson["maxMonetary"] = monetary[1];
            }
            if (
                (hvcScore[0] && hvcScore[1]) ||
                (hvcScore[0] >= 0 && hvcScore[1] >= 0)
            ) {
                if (hvcScore[0] !== allHvcScoreValue[0]) {
                    dataJson["minHvcScore"] = hvcScore[0];
                }
                if (hvcScore[1] !== allHvcScoreValue[1]) {
                    dataJson["maxHvcScore"] = hvcScore[1];
                }
            }
            if (ppv[0] !== allPpvValue[0]) {
                dataJson["minPpv"] = ppv[0];
            }
            if (ppv[1] !== allPpvValue[1]) {
                dataJson["maxPpv"] = ppv[1];
            }
            if (aov[0] !== allAovValue[0]) {
                dataJson["minAov"] = aov[0];
            }
            if (aov[1] !== allAovValue[1]) {
                dataJson["maxAov"] = aov[1];
            }
        }
        const url = "api/user_settings/push_audience/";
        dispatch({
            type: types.PUSH_AUDIENCE_REQUEST,
        });
        try {
            let response = await axios.post(url, dataJson, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access")}`,
                },
            });
            let responseData = await response.data;
            // console.log("responseData", responseData)
            dispatch({
                type: types.PUSH_AUDIENCE_SUCCESS,
                responseData,
            });
        } catch (e) {
            console.log("error in pushing audience= ", e);
            dispatch({
                type: types.PUSH_AUDIENCE_FAILED,
            });
        }
    };
}*/

/*export function push_audiences_fb_param(
    updateReplace,
    updateReplacePeriod,
    sources,
    recency,
    frequency,
    monetary,
    ppv,
    aov,
    hvcScore,
    allRecencyValue,
    allFrequencyValue,
    allMonetaryValue,
    allPpvValue,
    allAovValue,
    allHvcScoreValue,
    segment,
    customerURL,
    audience_name = "lxr_audience",
) {
    return async function (dispatch, getState) {
        let arraySources = [];
        arraySources.push(sources);
        let dataJson = {};
        if (Boolean(updateReplace)) {
            dataJson["update_replace"] = updateReplace;
            dataJson["update_replace_period"] = updateReplacePeriod;
        }
        dataJson["segment"] = segment;
        dataJson["sources"] = arraySources;
        dataJson["audience_name"] = audience_name;
        let pushParam = getState().application_states.pushParam;
        dataJson["preDefined"] = getState().application_states.pushPriorityParam;
        if (pushParam === "custom") {
            if (recency[0] !== allRecencyValue[0]) {
                dataJson["minRecency"] = recency[0];
            }
            if (recency[1] !== allRecencyValue[1]) {
                dataJson["maxRecency"] = recency[1];
            }
            if (frequency[0] !== allFrequencyValue[0]) {
                dataJson["minFrequency"] = frequency[0];
            }
            if (frequency[1] !== allFrequencyValue[1]) {
                dataJson["maxFrequency"] = frequency[1];
            }
            if (monetary[0] !== allMonetaryValue[0]) {
                dataJson["minMonetary"] = monetary[0];
            }
            if (monetary[1] !== allMonetaryValue[1]) {
                dataJson["maxMonetary"] = monetary[1];
            }
            if (ppv[0] !== allPpvValue[0]) {
                dataJson["minPpv"] = ppv[0];
            }
            if (ppv[1] !== allPpvValue[1]) {
                dataJson["maxPpv"] = ppv[1];
            }
            if (aov[0] !== allAovValue[0]) {
                dataJson["minAov"] = aov[0];
            }
            if (aov[1] !== allAovValue[1]) {
                dataJson["maxAov"] = aov[1];
            }
            if (
                (hvcScore[0] && hvcScore[1]) ||
                (hvcScore[0] >= 0 && hvcScore[1] >= 0)
            ) {
                if (hvcScore[0] !== allHvcScoreValue[0]) {
                    dataJson["minHvcScore"] = hvcScore[0];
                }
                if (hvcScore[1] !== allHvcScoreValue[1]) {
                    dataJson["maxHvcScore"] = hvcScore[1];
                }
            }
        }
        const url = "api/user_settings/facebook/push_audience/";
        dispatch({
            type: types.FB_PUSH_AUDIENCE_REQUEST,
        });
        try {
            let response = await axios.post(url, dataJson, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access")}`,
                },
            });
            let responseData = await response.data;
            // console.log("responseData", responseData)
            dispatch({
                type: types.FB_PUSH_AUDIENCE_SUCCESS,
                responseData,
            });
        } catch (e) {
            console.log("error in pushing audience= ", e);
            dispatch({
                type: types.FB_PUSH_AUDIENCE_FAILED,
            });
        }
    };
}*/

export function push_audiences_filter_param(
  updateReplace,
  updateReplacePeriod,
  source,
  filter_id,
  audience_name = "lxr_audience"
) {
  return async function (dispatch, getState) {
    let dataJson = {};
    if (Boolean(updateReplace)) {
      dataJson["operation_type"] = updateReplace;
      dataJson["frequency_months"] = updateReplacePeriod;
    }
    dataJson["channel"] = source;
    dataJson["audience_name"] = audience_name;
    let pushParam = getState().application_states.pushParam;
    // dataJson["preDefined"] = getState().application_states.pushPriorityParam;
    // if (pushParam === "custom") {
    let filterId = filter_id
      ? filter_id
      : getState().save_custom_filter_reducer.data.id;
    dataJson["filter_id"] = filterId;
    // }
    const url = "api/audience/push-audience/";
    dispatch({
      type: types.PUSH_AUDIENCE_REQUEST,
    });
    try {
      let response = await axios.post(url, dataJson, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let responseData = await response.data;
      dispatch({
        type: types.PUSH_AUDIENCE_SUCCESS,
        responseData,
      });
    } catch (e) {
      console.log("error in pushing audience= ", e);
      dispatch({
        type: types.PUSH_AUDIENCE_FAILED,
      });
    }
  };
}

export function get_user_hashedEmails(customerData, segment) {
  return async function (dispatch) {
    let dataJson;
    dataJson = customerData ? { customer_ids: segment } : { segment: segment };
    const url = "api/user_settings/list_audience/";
    dispatch({
      type: types.LIST_AUDIENCE_REQUEST,
      segment,
    });
    try {
      let response = await axios.post(url, dataJson, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let responseData = await response.data;
      // console.log("responseData", responseData)
      dispatch({
        type: types.LIST_AUDIENCE_SUCCESS,
        responseData,
      });
    } catch (e) {
      console.log("error in pushing customer ids= ", e);
      dispatch({
        type: types.LIST_AUDIENCE_FAILED,
      });
    }
  };
}

/*export function get_user_hashedEmails_params(
    recency,
    frequency,
    monetary,
    ppv,
    aov,
    hvcScore,
    allRecencyValue,
    allFrequencyValue,
    allMonetaryValue,
    allPpvValue,
    allAovValue,
    allHvcScoreValue,
    segment,
    // customerData,
    // customerList
) {
    return async function (dispatch, getState) {
        let request = segment;
        let dataJson = {};
        dataJson["segment"] = segment;
        let pushParam = getState().application_states.pushParam;
        dataJson["preDefined"] = getState().application_states.pushPriorityParam;
        if (pushParam === "custom") {
            if (recency[0] !== allRecencyValue[0]) {
                dataJson["minRecency"] = recency[0];
            }
            if (recency[1] !== allRecencyValue[1]) {
                dataJson["maxRecency"] = recency[1];
            }
            if (frequency[0] !== allFrequencyValue[0]) {
                dataJson["minFrequency"] = frequency[0];
            }
            if (frequency[1] !== allFrequencyValue[1]) {
                dataJson["maxFrequency"] = frequency[1];
            }
            if (monetary[0] !== allMonetaryValue[0]) {
                dataJson["minMonetary"] = monetary[0];
            }
            if (monetary[1] !== allMonetaryValue[1]) {
                dataJson["maxMonetary"] = monetary[1];
            }
            if (ppv[0] !== allPpvValue[0]) {
                dataJson["minPpv"] = ppv[0];
            }
            if (ppv[1] !== allPpvValue[1]) {
                dataJson["maxPpv"] = ppv[1];
            }
            if (aov[0] !== allAovValue[0]) {
                dataJson["minAov"] = aov[0];
            }
            if (aov[1] !== allAovValue[1]) {
                dataJson["maxAov"] = aov[1];
            }
            if (
                (hvcScore[0] && hvcScore[1]) ||
                (hvcScore[0] >= 0 && hvcScore[1] >= 0)
            ) {
                if (hvcScore[0] !== allHvcScoreValue[0]) {
                    dataJson["minHvcScore"] = hvcScore[0];
                }
                if (hvcScore[1] !== allHvcScoreValue[1]) {
                    dataJson["maxHvcScore"] = hvcScore[1];
                }
            }
        }
        const url = "api/user_settings/list_audience/";
        dispatch({
            type: types.LIST_AUDIENCE_REQUEST,
            request,
        });
        try {
            let response = await axios.post(url, dataJson, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access")}`,
                },
            });
            let responseData = await response.data;
            // console.log("responseData", responseData)
            dispatch({
                type: types.LIST_AUDIENCE_SUCCESS,
                responseData,
            });
        } catch (e) {
            console.log("error in pushing customer ids= ", e);
            dispatch({
                type: types.LIST_AUDIENCE_FAILED,
            });
        }
    };
}*/

export function get_user_hashedEmails_filter_id(filter_id) {
  return async function (dispatch, getState) {
    let request = { filter_id: filter_id };
    let reportButtonValueApplied =
      getState().application_states.reportButtonValueApplied;
    if (!reportButtonValueApplied) {
      let dataJson = {};
      dataJson["filter_id"] = filter_id;
      const url = "api/audience/list-audience/";
      dispatch({
        type: types.LIST_AUDIENCE_REQUEST,
        request,
      });
      try {
        let response = await axios.post(url, dataJson, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        });
        let responseData = await response.data;
        dispatch({
          type: types.LIST_AUDIENCE_SUCCESS,
          responseData,
        });
      } catch (e) {
        console.log("error in downloading report= ", e);
        dispatch({
          type: types.LIST_AUDIENCE_FAILED,
        });
      }
    } else {
      let dataJson = {};
      let remarket_page_states = getState().remarket_page_states;
      dataJson["segment_a"] = remarket_page_states.segment_a;
      dataJson["segment_b"] = remarket_page_states.segment_b;
      dataJson["segment_c"] = remarket_page_states.segment_c;
      if (
        remarket_page_states.recency[0] !==
        remarket_page_states.allRecencyValue[0]
      ) {
        dataJson["minRecency"] = remarket_page_states.recency[0];
      }
      if (
        remarket_page_states.recency[0] !==
        remarket_page_states.allRecencyValue[0]
      ) {
        dataJson["maxRecency"] = remarket_page_states.recency[1];
      }
      if (
        remarket_page_states.frequency[0] !==
        remarket_page_states.allFrequencyValue[0]
      ) {
        dataJson["minFrequency"] = remarket_page_states.frequency[0];
      }
      if (
        remarket_page_states.frequency[0] !==
        remarket_page_states.allFrequencyValue[0]
      ) {
        dataJson["maxFrequency"] = remarket_page_states.frequency[1];
      }
      if (
        remarket_page_states.monetary[0] !==
        remarket_page_states.allMonetaryValue[0]
      ) {
        dataJson["minMonetary"] = remarket_page_states.monetary[0];
      }
      if (
        remarket_page_states.monetary[0] !==
        remarket_page_states.allMonetaryValue[0]
      ) {
        dataJson["maxMonetary"] = remarket_page_states.monetary[1];
      }
      if (remarket_page_states.ppv[0] !== remarket_page_states.allPpvValue[0]) {
        dataJson["minPpv"] = remarket_page_states.ppv[0];
      }
      if (remarket_page_states.ppv[0] !== remarket_page_states.allPpvValue[0]) {
        dataJson["maxPpv"] = remarket_page_states.ppv[1];
      }
      if (remarket_page_states.aov[0] !== remarket_page_states.allAovValue[0]) {
        dataJson["minAov"] = remarket_page_states.aov[0];
      }
      if (remarket_page_states.aov[0] !== remarket_page_states.allAovValue[0]) {
        dataJson["maxAov"] = remarket_page_states.aov[1];
      }
      dataJson["pred_single_repeat_buyer"] =
        remarket_page_states.pred_single_repeat_buyer;
      dataJson["pred_repeat_buyer_2m"] =
        remarket_page_states.pred_repeat_buyer_2m;
      dataJson["pred_churn"] = remarket_page_states.pred_churn;
      if (
        (remarket_page_states.hvcScore[0] &&
          remarket_page_states.hvcScore[1]) ||
        (remarket_page_states.hvcScore[0] >= 0 &&
          remarket_page_states.hvcScore[1] >= 0)
      ) {
        if (
          remarket_page_states.hvcScore[0] !==
          remarket_page_states.allHvcScoreValue[0]
        ) {
          dataJson["minHvcScore"] = remarket_page_states.hvcScore[0];
        }
        if (
          remarket_page_states.hvcScore[0] !==
          remarket_page_states.allHvcScoreValue[0]
        ) {
          dataJson["maxHvcScore"] = remarket_page_states.hvcScore[1];
        }
      }
      const url = "api/user_settings/list_audience-new/";
      dispatch({
        type: types.LIST_AUDIENCE_REQUEST,
        request,
      });
      try {
        let response = await axios.post(url, dataJson, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        });
        let responseData = await response.data;
        // console.log("responseData", responseData)
        dispatch({
          type: types.LIST_AUDIENCE_SUCCESS,
          responseData,
        });
      } catch (e) {
        console.log("error in pushing customer ids= ", e);
        dispatch({
          type: types.LIST_AUDIENCE_FAILED,
        });
      }
    }
  };
}

export const emailListAudienceReset = (payload) => ({
  type: types.LIST_AUDIENCE_RESET,
});

export function get_customer_custom_report(
  recency,
  frequency,
  monetary,
  ppv,
  aov,
  hvcScore,
  segment
) {
  return async function (dispatch, getState) {
    let dataJson = {};
    dataJson["segment"] = segment;
    let pushParam = getState().application_states.pushParam;
    let pushPriorityParam = getState().application_states.pushPriorityParam;
    dataJson["preDefined"] = pushPriorityParam;
    if (pushParam === "custom") {
      if (_.isEqual(segment, ["all"])) {
        dataJson["segment"] = ["a", "b", "c"];
      }
      // if (recency[0] !== allRecencyValue[0]) {
      dataJson["minRecency"] = recency[0];
      // }
      // if (recency[1] !== allRecencyValue[1]) {
      dataJson["maxRecency"] = recency[1];
      // }
      // if (frequency[0] !== allFrequencyValue[0]) {
      dataJson["minFrequency"] = frequency[0];
      // }
      // if (frequency[1] !== allFrequencyValue[1]) {
      dataJson["maxFrequency"] = frequency[1];
      // }
      // if (monetary[0] !== allMonetaryValue[0]) {
      dataJson["minMonetary"] = monetary[0];
      // }
      // if (monetary[1] !== allMonetaryValue[1]) {
      dataJson["maxMonetary"] = monetary[1];
      // }
      // if (ppv[0] !== allPpvValue[0]) {
      dataJson["minPpv"] = ppv[0];
      // }
      // if (ppv[1] !== allPpvValue[1]) {
      dataJson["maxPpv"] = ppv[1];
      // }
      // if (aov[0] !== allAovValue[0]) {
      dataJson["minAov"] = aov[0];
      // }
      // if (aov[1] !== allAovValue[1]) {
      dataJson["maxAov"] = aov[1];
      // }
      if (
        (hvcScore[0] && hvcScore[1]) ||
        (hvcScore[0] >= 0 && hvcScore[1] >= 0)
      ) {
        // if (hvcScore[0] !== allHvcScoreValue[0]) {
        dataJson["minHvcScore"] = hvcScore[0];
        // }
        // if (hvcScore[1] !== allHvcScoreValue[1]) {
        dataJson["maxHvcScore"] = hvcScore[1];
        // }
      }
    }
    const url = "api/user_settings/custom-report-download/";
    dispatch({
      type: types.CUSTOMER_REPORT_CUSTOM_REQUEST,
    });
    try {
      let response = await axios.post(url, dataJson, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let responseData = await response.data;
      // console.log("responseData", responseData)
      dispatch({
        type: types.CUSTOMER_REPORT_CUSTOM_SUCCESS,
        responseData,
      });
    } catch (e) {
      console.log("error in downloading report= ", e);
      dispatch({
        type: types.CUSTOMER_REPORT_CUSTOM_FAILED,
      });
    }
  };
}

export function get_customer_custom_report_filter_id(filterId) {
  return async function (dispatch, getState) {
    let reportButtonValueApplied =
      getState().application_states.reportButtonValueApplied;
    if (!reportButtonValueApplied) {
      let dataJson = {};
      dataJson["filter_id"] = filterId;
      const url = "api/audience/custom-report-download/";
      dispatch({
        type: types.CUSTOMER_REPORT_CUSTOM_REQUEST,
      });
      try {
        let response = await axios.post(url, dataJson, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        });
        let responseData = await response.data;
        dispatch({
          type: types.CUSTOMER_REPORT_CUSTOM_SUCCESS,
          responseData,
        });
      } catch (e) {
        console.log("error in downloading report= ", e);
        dispatch({
          type: types.CUSTOMER_REPORT_CUSTOM_FAILED,
        });
      }
    } else {
      let dataJson = {};
      let remarket_page_states = getState().remarket_page_states;
      dataJson["segment_a"] = remarket_page_states.segment_a;
      dataJson["segment_b"] = remarket_page_states.segment_b;
      dataJson["segment_c"] = remarket_page_states.segment_c;
      if (
        remarket_page_states.recency[0] !==
        remarket_page_states.allRecencyValue[0]
      ) {
        dataJson["minRecency"] = remarket_page_states.recency[0];
      }
      if (
        remarket_page_states.recency[1] !==
        remarket_page_states.allRecencyValue[1]
      ) {
        dataJson["maxRecency"] = remarket_page_states.recency[1];
      }
      if (
        remarket_page_states.frequency[0] !==
        remarket_page_states.allFrequencyValue[0]
      ) {
        dataJson["minFrequency"] = remarket_page_states.frequency[0];
      }
      if (
        remarket_page_states.frequency[1] !==
        remarket_page_states.allFrequencyValue[1]
      ) {
        dataJson["maxFrequency"] = remarket_page_states.frequency[1];
      }
      if (
        remarket_page_states.monetary[0] !==
        remarket_page_states.allMonetaryValue[0]
      ) {
        dataJson["minMonetary"] = remarket_page_states.monetary[0];
      }
      if (
        remarket_page_states.monetary[1] !==
        remarket_page_states.allMonetaryValue[1]
      ) {
        dataJson["maxMonetary"] = remarket_page_states.monetary[1];
      }
      if (remarket_page_states.ppv[0] !== remarket_page_states.allPpvValue[0]) {
        dataJson["minPpv"] = remarket_page_states.ppv[0];
      }
      if (remarket_page_states.ppv[1] !== remarket_page_states.allPpvValue[1]) {
        dataJson["maxPpv"] = remarket_page_states.ppv[1];
      }
      if (remarket_page_states.aov[0] !== remarket_page_states.allAovValue[0]) {
        dataJson["minAov"] = remarket_page_states.aov[0];
      }
      if (remarket_page_states.aov[1] !== remarket_page_states.allAovValue[1]) {
        dataJson["maxAov"] = remarket_page_states.aov[1];
      }
      dataJson["pred_single_repeat_buyer"] =
        remarket_page_states.pred_single_repeat_buyer;
      dataJson["pred_repeat_buyer_2m"] =
        remarket_page_states.pred_repeat_buyer_2m;
      dataJson["pred_churn"] = remarket_page_states.pred_churn;
      if (
        (remarket_page_states.hvcScore[0] &&
          remarket_page_states.hvcScore[1]) ||
        (remarket_page_states.hvcScore[0] >= 0 &&
          remarket_page_states.hvcScore[1] >= 0)
      ) {
        if (
          remarket_page_states.hvcScore[0] !==
          remarket_page_states.allHvcScoreValue[0]
        ) {
          dataJson["minHvcScore"] = remarket_page_states.hvcScore[0];
        }
        if (
          remarket_page_states.hvcScore[1] !==
          remarket_page_states.allHvcScoreValue[1]
        ) {
          dataJson["maxHvcScore"] = remarket_page_states.hvcScore[1];
        }
      }
      const url = "api/user_settings/custom-report-download-new/";
      dispatch({
        type: types.CUSTOMER_REPORT_CUSTOM_REQUEST,
      });
      try {
        let response = await axios.post(url, dataJson, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        });
        let responseData = await response.data;
        // console.log("responseData", responseData)
        dispatch({
          type: types.CUSTOMER_REPORT_CUSTOM_SUCCESS,
          responseData,
        });
      } catch (e) {
        console.log("error in downloading report= ", e);
        dispatch({
          type: types.CUSTOMER_REPORT_CUSTOM_FAILED,
        });
      }
    }
  };
}

export function delete_channel_connection(source) {
  return async function (dispatch) {
    // console.log("arraySources", arraySources)
    // console.log("sources", sources)
    // console.log("segment", segment)
    // console.log("audience_name", audience_name)
    const url = `api/user_settings/delete_connection/?source=${source}`;
    dispatch({
      type: types.DELETE_CONNECTION_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let responseData = await response.data;
      // console.log("responseData", responseData)
      dispatch({
        type: types.DELETE_CONNECTION_SUCCESS,
        responseData,
      });
    } catch (e) {
      console.log("error in deleting connection= ", e);
      dispatch({
        type: types.DELETE_CONNECTION_FAILED,
      });
    }
  };
}

/*
export function redeal_campaign_analytics(start, end) {
  return async function (dispatch) {
    const url = `api/audience/analytics/redeal/?start=${start}&end=${end}`;
    // const url = `redealsample.JSON`;
    dispatch({
      type: types.REDEAL_ANALYTICS_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let responseData = await response.data;
      dispatch({
        type: types.REDEAL_ANALYTICS_SUCCESS,
        responseData,
      });
    } catch (e) {
      console.log("error in fetching redeal= ", e);
      dispatch({
        type: types.REDEAL_ANALYTICS_FAILED,
      });
    }
  };
}*/

export function googlead_active_audience() {
  return async function (dispatch) {
    // const url = `/active_audience_sample.JSON`;
    const url = `api/audience/active-audience/`;
    dispatch({
      type: types.GOOGLEAD_ACTIVE_LIST_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let responseData = await response.data;
      dispatch({
        type: types.GOOGLEAD_ACTIVE_LIST_SUCCESS,
        responseData,
      });
      // console.log("responseData", responseData);
    } catch (e) {
      console.log("error in fetching googlead active list = ", e);
      dispatch({
        type: types.GOOGLEAD_ACTIVE_LIST_FAILED,
      });
    }
  };
}

export function audience_source_analytics(source, start, end, aud_rs_name) {
  return async function (dispatch) {
    // const url = source === 'googleads' ? `/ga_analytics.JSON` : `redealsample.JSON`;
    const url = `api/audience/analytics/${source}/?start=${start}&end=${end}&aud_rs_name=${encodeURIComponent(
      aud_rs_name
    )}`;
    if (source === "googleads") {
      dispatch({
        type: types.GOOGLEAD_ANALYTICS_REQUEST,
      });
      try {
        let response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        });
        let responseData = await response.data;

        dispatch({
          type: types.GOOGLEAD_ANALYTICS_SUCCESS,
          responseData,
        });
        // console.log("responseData", responseData);
      } catch (e) {
        console.log("error in fetching googlead analytics= ", e);
        dispatch({
          type: types.GOOGLEAD_ANALYTICS_FAILED,
        });
      }
    } else if (source === "redeal") {
      dispatch({
        type: types.REDEAL_ANALYTICS_REQUEST,
      });
      try {
        let response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        });
        let responseData = await response.data;

        dispatch({
          type: types.REDEAL_ANALYTICS_SUCCESS,
          responseData,
        });
        // console.log("responseData", responseData);
      } catch (e) {
        console.log("error in fetching redeal analytics = ", e);
        dispatch({
          type: types.REDEAL_ANALYTICS_FAILED,
        });
      }
    }
  };
}

export function audience_source_compare_analytics(
  source,
  start,
  end,
  aud_rs_name
) {
  return async function (dispatch) {
    // const url = source === 'googleads' ? `/sampleOverviewData.JSON` : `redealsample.JSON`;
    const url = `api/audience/analytics/${source}/?start=${start}&end=${end}&aud_rs_name=${encodeURIComponent(
      aud_rs_name
    )}`;
    if (source === "googleads") {
      dispatch({
        type: types.GOOGLEAD_COMPARE_ANALYTICS_REQUEST,
      });
      try {
        let response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        });
        let responseData = await response.data;

        dispatch({
          type: types.GOOGLEAD_COMPARE_ANALYTICS_SUCCESS,
          responseData,
        });
        // console.log("responseData", responseData);
      } catch (e) {
        console.log("error in fetching googlead analytics= ", e);
        dispatch({
          type: types.GOOGLEAD_COMPARE_ANALYTICS_FAILED,
        });
      }
    } else if (source === "redeal") {
      dispatch({
        type: types.REDEAL_COMPARE_ANALYTICS_REQUEST,
      });
      try {
        let response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        });
        let responseData = await response.data;

        dispatch({
          type: types.REDEAL_COMPARE_ANALYTICS_SUCCESS,
          responseData,
        });
        // console.log("responseData", responseData);
      } catch (e) {
        console.log("error in fetching redeal analytics = ", e);
        dispatch({
          type: types.REDEAL_COMPARE_ANALYTICS_FAILED,
        });
      }
    }
  };
}

export function ReportDownloaded(report_filename, report_type, segment) {
  return async function (dispatch) {
    const url = `api/user_settings/report-downloaded/`;
    const reqData = {
      report_filename: report_filename,
      report_type: report_type,
      segment: segment,
    };
    dispatch({
      type: types.REPORT_DOWNLOAD_ENTRY_REQUEST,
    });
    try {
      let response = await axios.post(url, reqData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      // console.log("response", response)
      dispatch({
        type: types.REPORT_DOWNLOAD_ENTRY_SUCCESS,
        response,
      });
    } catch (e) {
      console.log("error in fetch= ", e);
      dispatch({
        type: types.REPORT_DOWNLOAD_ENTRY_FAILURE,
      });
    }
  };
}

export function single_buyer_repeat() {
  return async function (dispatch) {
    const url = `api/landing_insights/single_repeat_buyer`;
    // const url = "/repeatbuyers.JSON";
    dispatch({
      type: types.SINGLE_BUYER_REPEAT_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let responseData = await response.data;
      // console.log("responseData", responseData)
      dispatch({
        type: types.SINGLE_BUYER_REPEAT_SUCCESS,
        responseData,
      });
    } catch (e) {
      console.log("error in fetching single buyer repeat= ", e);
      dispatch({
        type: types.SINGLE_BUYER_REPEAT_FAILED,
      });
    }
  };
}

export function repeat_next_3months() {
  return async function (dispatch) {
    const url = `api/landing_insights/repeat_buyer_next2ms`;
    // const url = `redealsample.JSON`;
    dispatch({
      type: types.REPEAT_NEXT_3M_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let responseData = await response.data;
      // console.log("responseData", responseData)
      dispatch({
        type: types.REPEAT_NEXT_3M_SUCCESS,
        responseData,
      });
    } catch (e) {
      console.log("error in fetching repeat next 2 months= ", e);
      dispatch({
        type: types.REPEAT_NEXT_3M_FAILED,
      });
    }
  };
}

export function visitor_to_customer() {
  return async function (dispatch) {
    const url = `api/customergroup/landing_insights/predicted_visitor_insights`;
    // const url = `redealsample.JSON`;
    dispatch({
      type: types.VISITOR_TO_CUSTOMER_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let responseData = await response.data;
      // console.log("responseData", responseData)
      dispatch({
        type: types.VISITOR_TO_CUSTOMER_SUCCESS,
        responseData,
      });
    } catch (e) {
      console.log("error in fetching visitor to customer= ", e);
      dispatch({
        type: types.VISITOR_TO_CUSTOMER_FAILED,
      });
    }
  };
}

export function predictions_summary() {
  return async function (dispatch) {
    const url = `api/customergroup/current_prediction_status`;
    // const url = `redealsample.JSON`;
    dispatch({
      type: types.PREDICTIONS_SUMMARY_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let responseData = await response.data;
      // console.log("responseData", responseData)
      dispatch({
        type: types.PREDICTIONS_SUMMARY_SUCCESS,
        responseData,
      });
    } catch (e) {
      console.log("error in fetching predictions summary= ", e);
      dispatch({
        type: types.PREDICTIONS_SUMMARY_FAILED,
      });
    }
  };
}

export function audience_history_analytics(start, end) {
  return async function (dispatch) {
    const url =
      start && end
        ? `api/audience/audience_history/?start=${start}&end=${end}`
        : `api/audience/audience_history/`;
    // const url = `audienceHistory.JSON`;
    dispatch({
      type: types.AUDIENCE_HISTORY_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let responseData = await response.data;
      dispatch({
        type: types.AUDIENCE_HISTORY_SUCCESS,
        responseData,
      });
    } catch (e) {
      console.log("error in fetching audience history analytics= ", e);
      dispatch({
        type: types.AUDIENCE_HISTORY_FAILED,
      });
    }
  };
}

/*
export function update_replace_existing_list(value, updateReplace, updateReplacePeriod, sources, recency, frequency, monetary, ppv, aov, hvcScore, allRecencyValue, allFrequencyValue, allMonetaryValue, allPpvValue, allAovValue, allHvcScoreValue, segment, customerURL, audience_name) {
    return async function (dispatch, getState) {
        let arraySources = [];
        arraySources.push(sources);
        let dataJson = {};
        dataJson["option"] = value;
        if (Boolean(updateReplace)) {
            dataJson["update_replace"] = updateReplace;
            dataJson["update_replace_period"] = updateReplacePeriod;
        }
        dataJson["segment"] = segment;
        dataJson["sources"] = arraySources;
        dataJson["audience_name"] = audience_name;
        let pushParam = getState().application_states.pushParam;
        dataJson["preDefined"] = getState().application_states.pushPriorityParam;
        if (pushParam === "custom") {
            if (recency[0] !== allRecencyValue[0]) {
                dataJson["minRecency"] = recency[0];
            }
            if (recency[1] !== allRecencyValue[1]) {
                dataJson["maxRecency"] = recency[1];
            }
            if (frequency[0] !== allFrequencyValue[0]) {
                dataJson["minFrequency"] = frequency[0];
            }
            if (frequency[1] !== allFrequencyValue[1]) {
                dataJson["maxFrequency"] = frequency[1];
            }
            if (monetary[0] !== allMonetaryValue[0]) {
                dataJson["minMonetary"] = monetary[0];
            }
            if (monetary[1] !== allMonetaryValue[1]) {
                dataJson["maxMonetary"] = monetary[1];
            }
            if (ppv[0] !== allPpvValue[0]) {
                dataJson["minPpv"] = ppv[0];
            }
            if (ppv[1] !== allPpvValue[1]) {
                dataJson["maxPpv"] = ppv[1];
            }
            if (aov[0] !== allAovValue[0]) {
                dataJson["minAov"] = aov[0];
            }
            if (aov[1] !== allAovValue[1]) {
                dataJson["maxAov"] = aov[1];
            }
            if (hvcScore[0] !== allHvcScoreValue[0]) {
                dataJson["minHvcScore"] = hvcScore[0];
            }
            if (hvcScore[1] !== allHvcScoreValue[1]) {
                dataJson["maxHvcScore"] = hvcScore[1];
            }
        }
        const url = `api/user_settings/update_replace_existing_list/`;
        dispatch({
            type: types.UPDATE_REPLACE_REQUEST,
        });
        try {
            let response = await axios.post(url, dataJson, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access")}`,
                },
            });
            let responseData = await response.data;
            dispatch({
                type: types.UPDATE_REPLACE_SUCCESS,
                responseData,
            });
        } catch (e) {
            console.log("error in update/replace of existing list = ", e);
            dispatch({
                type: types.UPDATE_REPLACE_FAILED,
            });
        }
    };
}
*/

/*
export function update_replace_existing_fb_list(value, updateReplace, updateReplacePeriod, sources, recency, frequency, monetary, ppv, aov, hvcScore, allRecencyValue, allFrequencyValue, allMonetaryValue, allPpvValue, allAovValue, allHvcScoreValue, segment, customerURL, audience_name) {
    return async function (dispatch, getState) {
        let arraySources = [];
        arraySources.push(sources);
        let dataJson = {};
        dataJson["option"] = value;
        if (Boolean(updateReplace)) {
            dataJson["update_replace"] = updateReplace;
            dataJson["update_replace_period"] = updateReplacePeriod;
        }
        dataJson["segment"] = segment;
        dataJson["sources"] = arraySources;
        dataJson["audience_name"] = audience_name;
        let pushParam = getState().application_states.pushParam;
        dataJson["preDefined"] = getState().application_states.pushPriorityParam;
        if (pushParam === "custom") {
            if (recency[0] !== allRecencyValue[0]) {
                dataJson["minRecency"] = recency[0];
            }
            if (recency[1] !== allRecencyValue[1]) {
                dataJson["maxRecency"] = recency[1];
            }
            if (frequency[0] !== allFrequencyValue[0]) {
                dataJson["minFrequency"] = frequency[0];
            }
            if (frequency[1] !== allFrequencyValue[1]) {
                dataJson["maxFrequency"] = frequency[1];
            }
            if (monetary[0] !== allMonetaryValue[0]) {
                dataJson["minMonetary"] = monetary[0];
            }
            if (monetary[1] !== allMonetaryValue[1]) {
                dataJson["maxMonetary"] = monetary[1];
            }
            if (ppv[0] !== allPpvValue[0]) {
                dataJson["minPpv"] = ppv[0];
            }
            if (ppv[1] !== allPpvValue[1]) {
                dataJson["maxPpv"] = ppv[1];
            }
            if (aov[0] !== allAovValue[0]) {
                dataJson["minAov"] = aov[0];
            }
            if (aov[1] !== allAovValue[1]) {
                dataJson["maxAov"] = aov[1];
            }
            if (hvcScore[0] !== allHvcScoreValue[0]) {
                dataJson["minHvcScore"] = hvcScore[0];
            }
            if (hvcScore[1] !== allHvcScoreValue[1]) {
                dataJson["maxHvcScore"] = hvcScore[1];
            }
        }
        const url = `api/user_settings/facebook/update_replace_existing_list/`;
        dispatch({
            type: types.FB_UPDATE_REPLACE_REQUEST,
        });
        try {
            let response = await axios.post(url, dataJson, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access")}`,
                },
            });
            let responseData = await response.data;
            dispatch({
                type: types.FB_UPDATE_REPLACE_SUCCESS,
                responseData,
            });
        } catch (e) {
            console.log("error in update/replace of existing fb list = ", e);
            dispatch({
                type: types.FB_UPDATE_REPLACE_FAILED,
            });
        }
    };
}
*/

export function edit_audience_action(
  id,
  source,
  audience_name,
  operation,
  operation_period
) {
  return async function (dispatch) {
    const url = `api/user_settings/edit_active_audience/`;
    const reqData = {
      id: id,
      source: source,
      audience_name: audience_name,
      operation: operation,
      operation_period: operation_period === "none" ? null : operation_period,
    };
    dispatch({
      type: types.EDIT_AUDIENCE_REQUEST,
    });
    try {
      let response = await axios.post(url, reqData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let responseData = await response.data;
      // console.log("response", response)
      dispatch({
        type: types.EDIT_AUDIENCE_SUCCESS,
        responseData,
      });
    } catch (e) {
      console.log("error in fetch= ", e);
      dispatch({
        type: types.EDIT_AUDIENCE_FAILED,
      });
    }
  };
}

export function contact_us(data) {
  return async function (dispatch) {
    const url = "/api/customercontact/";
    dispatch({
      type: types.CONTACTUS_FORM_REQUEST,
    });
    try {
      let response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.CONTACTUS_FORM_SUCCESS,
        resultData,
      });
    } catch (e) {
      console.log("error in fetching query = ", e);
      let resultData;
      if (e.response) {
        resultData = e.response.data;
      }
      dispatch({
        type: types.CONTACTUS_FORM_FAILED,
        resultData,
      });
    }
  };
}

export function customer_trend() {
  return async function (dispatch) {
    // const url = `/customertrend.JSON`;
    // const url = `/api/trend/get_trend_data/`;
    const url = `/api/trend/group-trends-data/`;

    dispatch({
      type: types.CUSTOMER_TREND_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: { Authorization: `Bearer ${localStorage.getItem("access")}` },
      });
      let resultData = await response.data;

      // console.log(resultData, "resultData");
      dispatch({
        type: types.CUSTOMER_TREND_SUCCESS,
        resultData,
      });
    } catch (e) {
      console.log("error in fetching customer trend= ", e);
      dispatch({
        type: types.CUSTOMER_TREND_FAILURE,
      });
    }
  };
}

export function changeMetricValue(payload) {
  return function (dispatch) {
    dispatch({
      type: types.CUSTOMER_TREND_SELECTED_METRIC_VALUE,
      payload,
    });
  };
}

export function customerTrendGraphObject(payload) {
  return function (dispatch) {
    dispatch({
      type: types.CUSTOMER_TREND_GRAPH_DATA_OBJ,
      payload,
    });
  };
}

export function churn_prediction() {
  return async function (dispatch) {
    const url = `api/landing_insights/churn_prediction`;
    dispatch({
      type: types.CHURN_PREDICTION_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let responseData = await response.data;
      // console.log("responseData", responseData)
      dispatch({
        type: types.CHURN_PREDICTION_SUCCESS,
        responseData,
      });
    } catch (e) {
      console.log("error in fetching churn prediction = ", e);
      dispatch({
        type: types.CHURN_PREDICTION_FAILURE,
      });
    }
  };
}

export function downtime_banner() {
  return async function (dispatch) {
    const url = `api/web/downtime-banner/`;
    dispatch({
      type: types.DOWNTIME_BANNER_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let responseData = await response.data;
      // console.log("responseData", responseData)
      dispatch({
        type: types.DOWNTIME_BANNER_SUCCESS,
        responseData,
      });
    } catch (e) {
      console.log("error in fetching downtime banner = ", e);
      dispatch({
        type: types.DOWNTIME_BANNER_FAILURE,
      });
    }
  };
}

export function save_custom_filter(
  filterName,
  segment_a,
  segment_b,
  segment_c,
  recencyValue,
  frequencyValue,
  monetaryValue,
  aovValue,
  ppvValue,
  hvcScoreValue,
  allRecencyValue,
  allFrequencyValue,
  allMonetaryValue,
  allAovValue,
  allPpvValue,
  allHvcScoreValue,
  pred_repeat_buyer_2m,
  pred_single_repeat_buyer,
  pred_churn,
  type = "custom"
) {
  return async function (dispatch, getState) {
    const userProfile = getState().userProfile;
    let dataJson = {};
    dataJson["bu_id"] = userProfile.bu_id;
    dataJson["type"] = type;
    dataJson["filter_name"] = filterName;
    dataJson["segment_a"] = segment_a;
    dataJson["segment_b"] = segment_b;
    dataJson["segment_c"] = segment_c;
    dataJson["pred_repeat_buyer_2m"] = pred_repeat_buyer_2m;
    dataJson["pred_single_repeat_buyer"] = pred_single_repeat_buyer;
    dataJson["pred_churn"] = pred_churn;
    if (recencyValue[0] !== allRecencyValue[0]) {
      dataJson["min_recency"] = recencyValue[0];
    }
    if (recencyValue[1] !== allRecencyValue[1]) {
      dataJson["max_recency"] = recencyValue[1];
    }
    if (frequencyValue[0] !== allFrequencyValue[0]) {
      dataJson["min_frequency"] = frequencyValue[0];
    }
    if (frequencyValue[1] !== allFrequencyValue[1]) {
      dataJson["max_frequency"] = frequencyValue[1];
    }
    if (monetaryValue[0] !== allMonetaryValue[0]) {
      dataJson["min_monetary"] = monetaryValue[0];
    }
    if (monetaryValue[1] !== allMonetaryValue[1]) {
      dataJson["max_monetary"] = monetaryValue[1];
    }
    if (ppvValue[0] !== allPpvValue[0]) {
      dataJson["min_ppv"] = ppvValue[0];
    }
    if (ppvValue[1] !== allPpvValue[1]) {
      dataJson["max_ppv"] = ppvValue[1];
    }
    if (aovValue[0] !== allAovValue[0]) {
      dataJson["min_aov"] = aovValue[0];
    }
    if (aovValue[1] !== allAovValue[1]) {
      dataJson["max_aov"] = aovValue[1];
    }
    if (aovValue[0] !== allAovValue[0]) {
      dataJson["min_aov"] = aovValue[0];
    }
    if (aovValue[1] !== allAovValue[1]) {
      dataJson["max_aov"] = aovValue[1];
    }
    if (hvcScoreValue[0] !== allHvcScoreValue[0]) {
      dataJson["min_hvc_score"] = hvcScoreValue[0];
    }
    if (hvcScoreValue[1] !== allHvcScoreValue[1]) {
      dataJson["max_hvc_score"] = hvcScoreValue[1];
    }
    const url = "/api/audience/customer-filters/";
    dispatch({
      type: types.SAVE_CUSTOM_FILTER_REQUEST,
    });
    try {
      let response = await axios.post(url, dataJson, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
          "Content-Type": "application/json",
        },
      });
      let resultData = await response.data;
      await dispatch({
        type: types.SAVE_CUSTOM_FILTER_SUCCESS,
        resultData,
      });
      await dispatch({
        type: types.ADD_CUSTOM_FILTER_OBJECT,
        data: resultData,
      });
    } catch (e) {
      console.log("error in saving custom filter = ", e);
      dispatch({
        type: types.SAVE_CUSTOM_FILTER_FAILED,
      });
    }
  };
}

export const resetFetchCustomFilterArray = () => ({
  type: types.FETCH_CUSTOM_FILTER_RESET,
});

export const synchedAudiencesHistoryButton = (payload) => ({
  type: types.SYNCHED_AUDIENCE_HISTORY_CLICKED,
  payload,
});

export const selectedAudienceDetails = (payload) => ({
  type: types.SELECTED_AUDIENCE_DATA,
  payload,
});

export const selectedSynchedAudiencesDetails = (payload) => ({
  type: types.SELECTED_SYNCHED_AUDIENCE_DATA,
  payload,
});

export const resetCustomFilterAudiencesArray = () => ({
  type: types.GET_CUSTOM_FILTER_AUDIENCE_RESET,
});

export const updateFrequencyAudienceReset = () => ({
  type: types.SYNCED_AUDIENCE_EDIT_RESET,
});

export const syncedAudienceStatusReset = () => ({
  type: types.SYNCED_AUDIENCE_ENABLE_DISABLE_RESET,
});

export const handleSuccessModal = (payload) => ({
  type: types.SUCCESS_MODAL_VALUE,
  payload,
});

export const handleFailureModal = (payload) => ({
  type: types.FAILURE_MODAL_VALUE,
  payload,
});

export const handleRemarketPopUpStatus = (payload) => ({
  type: types.REMARKET_POPUP_STATUS,
  payload,
});

export function fetch_custom_filters() {
  return async function (dispatch) {
    const url = "/api/audience/customer-filters/";
    dispatch({
      type: types.FETCH_CUSTOM_FILTER_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.FETCH_CUSTOM_FILTER_SUCCESS,
        resultData,
      });
    } catch (e) {
      console.log("error in fetching filters = ", e);
      dispatch({
        type: types.FETCH_CUSTOM_FILTER_FAILED,
      });
    }
  };
}

export function getCustomFilterAudiences() {
  return async function (dispatch) {
    const url = `/api/audience/all-customer-filters/audiences/`;

    dispatch({
      type: types.GET_CUSTOM_FILTER_AUDIENCE_REQUEST,
    });

    const config = {
      method: "get",
      url,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    };

    try {
      const response = await axios(config);
      let resultData = await response.data;

      dispatch({
        type: types.GET_CUSTOM_FILTER_AUDIENCE_SUCCESS,
        resultData,
      });
    } catch (e) {
      console.log("error in fetching custom filters audineces = ", e);
      dispatch({
        type: types.GET_CUSTOM_FILTER_AUDIENCE_FAILED,
      });
    }
  };
}

export function editSyncedAudience(payload) {
  return async function (dispatch) {
    const url = `/api/audience/edit-audience/`;

    const config = {
      method: "post",
      url,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
      data: payload,
    };

    dispatch({
      type: types.SYNCED_AUDIENCE_EDIT_REQUEST,
    });

    try {
      let response = await axios(config);

      let resultData = await response.data;
      dispatch({
        type: types.SYNCED_AUDIENCE_EDIT_SUCCESS,
        resultData: { ...resultData, responseMessage: apiSuccess },
      });
    } catch (e) {
      console.log("error in editing synced audience = ", e);
      dispatch({
        type: types.SYNCED_AUDIENCE_EDIT_FAILED,
        resultData: { responseMessage: apiFailed },
      });
    }
  };
}

export function syncedStatusEnableDisableAudience(payload) {
  return async function (dispatch) {
    const url = `api/audience/enable-disable-audience/`;

    const config = {
      method: "post",
      url,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },

      data: payload,
    };

    dispatch({
      type: types.SYNCED_AUDIENCE_ENABLE_DISABLE_REQUEST,
    });

    try {
      let response = await axios(config);

      let resultData = await response.data;
      dispatch({
        type: types.SYNCED_AUDIENCE_ENABLE_DISABLE_SUCCESS,
        resultData: { ...resultData, responseMessage: apiSuccess },
      });
    } catch (e) {
      console.log("error in editing synced audience = ", e);
      dispatch({
        type: types.SYNCED_AUDIENCE_ENABLE_DISABLE_FAILED,
        resultData: { responseMessage: apiFailed },
      });
    }
  };
}

export function rename_filter(e, data) {
  return async function (dispatch) {
    const url = "/api/fetchcustomfilter/";
    dispatch({
      type: types.RENAME_FILTER_REQUEST,
    });
    try {
      let response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      let resultData = await response.data;
      // console.log(resultData, "resultData");
      dispatch({
        type: types.RENAME_FILTER_SUCCESS,
        resultData,
      });
    } catch (e) {
      console.log("error in renaming filter = ", e);
      dispatch({
        type: types.RENAME_FILTER_FAILED,
      });
    }
  };
}

export function generate_content(data, contentType) {
  return async function (dispatch) {
    const url = `api/generative/generate-content/${contentType}/`;
    dispatch({
      type: types.GENERATE_CONTENT_REQUEST,
    });
    try {
      let response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
          "X-CSRFToken": csrftoken,
        },
      });
      let responseData = await response.data;
      // console.log(response,"response")
      dispatch({
        type: types.GENERATE_CONTENT_SUCCESS,
        responseData,
      });
    } catch (e) {
      console.log("error in generating content = ", e);
      let resultData;
      if (e.response) {
        resultData = e.response;
      }
      dispatch({
        type: types.GENERATE_CONTENT_FAILURE,
        resultData,
      });
    }
  };
}

export function generate_more_content(data, contentType) {
  return async function (dispatch) {
    const url = `api/generative/generate-content/${contentType}/`;
    dispatch({
      type: types.GENERATE_MORE_CONTENT_REQUEST,
    });
    try {
      let response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
          "X-CSRFToken": csrftoken,
        },
      });
      let responseData = await response.data;
      // console.log(response,"response")
      dispatch({
        type: types.GENERATE_MORE_CONTENT_SUCCESS,
        responseData,
      });
    } catch (e) {
      console.log("error in generating more content = ", e);
      let resultData;
      if (e.response) {
        resultData = e.response;
      }
      dispatch({
        type: types.GENERATE_MORE_CONTENT_FAILURE,
        resultData,
      });
    }
  };
}

export function fetch_and_cluster() {
  return async function (dispatch) {
    const url = `/api/ecom/fetch-shopify-data-and-cluster/`;
    dispatch({
      type: types.FETCH_AND_CLUSTER_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
          "X-CSRFToken": csrftoken,
        },
      });
      let responseData = await response.data;
      // console.log(response,"response")
      dispatch({
        type: types.FETCH_AND_CLUSTER_SUCCESS,
        responseData,
      });
    } catch (e) {
      console.log("error in generating more content = ", e);
      let resultData;
      if (e.response) {
        resultData = e.response;
      }
      dispatch({
        type: types.FETCH_AND_CLUSTER_FAILED,
        resultData,
      });
    }
  };
}

export function shopify_hmac_verification(data) {
  return async function (dispatch) {
    const url = "/api/ecom/verify-shopify-hmac/";
    dispatch({
      type: types.SHOPIFY_APP_HMAC_REQUEST,
    });
    try {
      let response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.SHOPIFY_APP_HMAC_SUCCESS,
        resultData,
      });
    } catch (e) {
      console.log("error in shopify hmac validation e= ", e);
      let resultData;
      if (e.response) {
        resultData = e.response;
      }
      dispatch({
        type: types.SHOPIFY_APP_HMAC_FAILURE,
        resultData,
      });
    }
  };
}

export function shopify_bu_id_access_token(data) {
  return async function (dispatch) {
    localStorage.removeItem("source");
    localStorage.removeItem("code");
    localStorage.removeItem("shop");
    localStorage.removeItem("redirectURL");
    localStorage.removeItem("currentURL");
    const url = "/api/ecom/get-shopify-buId-access-token/";
    dispatch({
      type: types.SHOPIFY_BUID_ACCESS_TOKEN_REQUEST,
    });
    try {
      let response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let authResponse = await response.data;
      dispatch({
        type: types.SHOPIFY_BUID_ACCESS_TOKEN_SUCCESS,
        authResponse,
      });
    } catch (e) {
      console.log("error in shopify bu_id access token e= ", e);
      let resultData;
      if (e.response) {
        resultData = e.response;
      }
      dispatch({
        type: types.SHOPIFY_BUID_ACCESS_TOKEN_FAILURE,
        resultData,
      });
    }
  };
}

export function product_data(
  segment,
  season,
  region,
  order,
  orderBy,
  page,
  rowsPerPage,
  regionType
) {
  let params = {
    rank: "Rank",
    revenues_share: "Revenue",
    revenue: "Revenue",
    product: "product",
    customers: "Unique_Users",
    quantity: "Quantity",
    orders: "Orders",
    price: "price",
  };
  let finalData = {};
  finalData["segment"] = segment;
  finalData["season"] = season;
  finalData["region"] = region
    ? regionType[0] === "US"
      ? region.label
      : region.code
    : null;
  finalData["page_size"] = rowsPerPage;
  finalData["sort_feature"] = params[orderBy];
  finalData["page_number"] = page;
  finalData["sort_order"] = order === "asc" ? 1 : 0;
  return async function (dispatch) {
    const url = `/api/product-analytics/stats`;
    dispatch({
      type: types.PRODUCT_DATA_REQUEST,
    });
    try {
      if (
        !StoredSegmentData[
        url +
        segment +
        season +
        finalData.region +
        page +
        finalData.page_size +
        finalData.sort_feature +
        finalData.sort_order
        ]
      ) {
        let response = await axios.post(url, finalData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
            "X-CSRFToken": csrftoken,
          },
        });
        let resultData = await response.data;
        dispatch({
          type: types.PRODUCT_DATA_SUCCESS,
          resultData,
        });
        if (Object.keys(StoredSegmentData).length <= 60) {
          StoredSegmentData[
            url +
            segment +
            season +
            finalData.region +
            page +
            finalData.page_size +
            finalData.sort_feature +
            finalData.sort_order
          ] = resultData;
        } else {
          delete StoredSegmentData[Object.keys(StoredSegmentData)[0]];
          StoredSegmentData[
            url +
            segment +
            season +
            finalData.region +
            page +
            finalData.page_size +
            finalData.sort_feature +
            finalData.sort_order
          ] = resultData;
        }
      } else {
        let resultData = await StoredSegmentData[
          url +
          segment +
          season +
          finalData.region +
          page +
          finalData.page_size +
          finalData.sort_feature +
          finalData.sort_order
        ];
        dispatch({
          type: types.PRODUCT_DATA_SUCCESS,
          resultData,
        });
      }
    } catch (e) {
      console.log("error in fetching product data = ", e);
      let resultData;
      if (e.response) {
        resultData = e.response;
      }
      dispatch({
        type: types.PRODUCT_DATA_FAILED,
        resultData,
      });
    }
  };
}

export function product_category_data(segment, season) {
  let finalData = {};
  finalData["segment"] = segment;
  finalData["season"] = season;
  // finalData['region']=region?regionType[0] === "US"?region.label:region.code:null;
  return async function (dispatch) {
    const url = `api/product-analytics/category-region`;
    dispatch({
      type: types.PRODUCT_CATEGORY_DATA_REQUEST,
    });
    try {
      if (!StoredSegmentData[url + segment + season]) {
        let response = await axios.post(url, finalData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
            "X-CSRFToken": csrftoken,
          },
        });
        let resultData = await response.data;
        dispatch({
          type: types.PRODUCT_CATEGORY_DATA_SUCCESS,
          resultData,
        });
        if (Object.keys(StoredSegmentData).length <= 60) {
          StoredSegmentData[url + segment + season] = resultData;
        } else {
          delete StoredSegmentData[Object.keys(StoredSegmentData)[0]];
          StoredSegmentData[url + segment + season] = resultData;
        }
      } else {
        let resultData = await StoredSegmentData[url + segment + season];
        dispatch({
          type: types.PRODUCT_CATEGORY_DATA_SUCCESS,
          resultData,
        });
      }
    } catch (e) {
      console.log("error in fetching product data = ", e);
      let resultData;
      if (e.response) {
        resultData = e.response;
      }
      dispatch({
        type: types.PRODUCT_CATEGORY_DATA_FAILED,
        resultData,
      });
    }
  };
}

export function mfa_enable(data) {
  let enable = data === true ? "True" : "False";
  return async function (dispatch) {
    const url = `/api/core/mfa?mfa_mode=${enable}`;
    dispatch({
      type: types.MFA_ENABLE_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
          "X-CSRFToken": csrftoken,
        },
      });
      let resultData = response.data;
      dispatch({
        type: types.MFA_ENABLE_SUCCESS,
        resultData,
      });
    } catch (e) {
      console.log("error in Enabling MFA e= ", e);
      let resultData;
      if (e.response) {
        resultData = e.response;
      }
      dispatch({
        type: types.MFA_ENABLE_FAILURE,
        resultData,
      });
    }
  };
}

export function clearMFAEnableStatus() {
  return async function (dispatch) {
    let resultData = { message: "", qr_code_image: "" };
    dispatch({
      type: types.MFA_ENABLE_SUCCESS,
      resultData,
    });
  };
}

export function mfa_verification(data) {
  const finalData = {};
  finalData["mfa_code"] = data;
  return async function (dispatch) {
    const url = `/api/core/verify-mfa/`;
    dispatch({
      type: types.MFA_VERIFICATION_REQUEST,
    });
    try {
      let response = await axios.post(url, finalData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.MFA_VERIFICATION_SUCCESS,
        resultData,
      });
    } catch (e) {
      console.log("error in shopify hmac validation e= ", e);
      let resultData;
      if (e.response) {
        resultData = e.response;
      }
      dispatch({
        type: types.MFA_VERIFICATION_FAILURE,
        resultData,
      });
    }
  };
}

export function clearMFAVerfiyStatus() {
  return async function (dispatch) {
    let resultData = { message: "", status: "", code: "" };
    dispatch({
      type: types.MFA_VERIFICATION_SUCCESS,
      resultData,
    });
  };
}

export function get_product_page_meta_data() {
  return async function (dispatch) {
    const url = "/api/product-analytics/available-seasons";
    dispatch({
      type: types.PRODUCT_PAGE_META_DATA_REQUESTED,
    });
    try {
      let response = await axios.get(url, {
        headers: { Authorization: `Bearer ${localStorage.getItem("access")}` },
      });
      let resultData = await response.data;
      dispatch({
        type: types.PRODUCT_PAGE_META_DATA_RECEIVED,
        resultData,
      });
    } catch (e) {
      console.log("error in product page meta data fetch ", e);
      dispatch({
        type: types.PRODUCT_PAGE_META_DATA_FAILED,
      });
    }
  };
}

export function price_histogram(segment, season, region, regionType) {
  let finalData = {};
  finalData["segment"] = segment;
  finalData["season"] = season;
  finalData["region"] = region
    ? regionType[0] === "US"
      ? region.label
      : region.code
    : null;
  return async function (dispatch) {
    const url = `api/product-analytics/price-chart`;
    dispatch({
      type: types.PRICE_HISTOGRAM_REQUEST,
    });
    try {
      if (!StoredSegmentData[url + segment + season + finalData.region]) {
        let response = await axios.post(url, finalData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
            "X-CSRFToken": csrftoken,
          },
        });
        let resultData = await response.data;
        dispatch({
          type: types.PRICE_HISTOGRAM_SUCCESS,
          resultData,
        });
        if (Object.keys(StoredSegmentData).length <= 60) {
          StoredSegmentData[url + segment + season + finalData.region] =
            resultData;
        } else {
          delete StoredSegmentData[Object.keys(StoredSegmentData)[0]];
          StoredSegmentData[url + segment + season + finalData.region] =
            resultData;
        }
      } else {
        let resultData = await StoredSegmentData[
          url + segment + season + finalData.region
        ];
        dispatch({
          type: types.PRICE_HISTOGRAM_SUCCESS,
          resultData,
        });
      }
    } catch (e) {
      console.log("error in fetching product data = ", e);
      let resultData;
      if (e.response) {
        resultData = e.response;
      }
      dispatch({
        type: types.PRODUCT_DATA_FAILED,
        resultData,
      });
    }
  };
}

export function product_report(segment, season, region, regionType) {
  let finalData = {};
  finalData["segment"] = segment;
  finalData["season"] = season;
  finalData["region"] = region
    ? regionType && regionType === "US"
      ? region.label
      : region.code
    : null;
  finalData["is_download"] = true;
  return async function (dispatch) {
    const url = "/api/product-analytics/stats-reports";
    dispatch({
      type: types.PRODUCT_REPORT_REQUEST,
    });
    try {
      let response = await axios.post(url, finalData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
          "X-CSRFToken": csrftoken,
        },
        responseType: "blob",
      });
      let resultData = await response.data;
      // Create a blob URL for the Excel file
      const blobUrl = window.URL.createObjectURL(
        new Blob([resultData], { type: response.headers["content-type"] })
      );

      // Create an anchor tag
      const anchor = document.createElement("a");
      anchor.href = blobUrl;
      anchor.download = `Product Report ${segment === "a"
          ? "HVC"
          : segment === "b"
            ? "MVC"
            : segment === "c"
              ? "LVC"
              : "All Customers"
        }.xlsx`;

      // Trigger a click event on the anchor to start the download
      anchor.click();

      // Cleanup: Revoke the blob URL to free up resources
      window.URL.revokeObjectURL(blobUrl);

      dispatch({
        type: types.PRODUCT_REPORT_SUCCESS,
        resultData,
      });
    } catch (e) {
      console.log("error in fetching product data = ", e);
      let resultData;
      if (e.response) {
        resultData = e.response;
      }
      dispatch({
        type: types.PRODUCT_REPORT_FAILED,
        resultData,
      });
    }
  };
}

export function create_experiment(data, type, id) {
  return async function (dispatch, getState) {
    const property_id = getState().userProfile.bu_id;
    let url;
    if (id) {
      url = `/api/experiments/experiment-data/${property_id}/${id}/`;
    } else {
      url = `/api/experiments/experiment-data/${property_id}/`;
    }
    dispatch({
      type: types.CREATE_EXPERIMENT_REQUEST,
    });
    try {
      let response = await axios[type](url, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      await dispatch({
        type: types.CREATE_EXPERIMENT_SUCCESS,
        response,
      });
    } catch (e) {
      console.log("error in saving experiment = ", e);
      let response;
      if (e.response) {
        response = e.response;
      }
      await dispatch({
        type: types.CREATE_EXPERIMENT_FAILED,
        response,
      });
    }
  };
}

export function delete_experiment(id) {
  return async function (dispatch, getState) {
    const property_id = getState().userProfile.bu_id;
    let url = `/api/experiments/experiment-data/${property_id}/${id}/`;
    dispatch({
      type: types.DELETE_EXPERIMENT_REQUEST,
    });
    try {
      let response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      await dispatch({
        type: types.DELETE_EXPERIMENT_SUCCESS,
        response,
      });
    } catch (e) {
      console.log("error in deleteing experiment = ", e);
      let response;
      if (e.response) {
        response = e.response;
      }
      await dispatch({
        type: types.DELETE_EXPERIMENT_FAILED,
        response,
      });
    }
  };
}

export function exp_audience_fetch() {
  return async function (dispatch, getState) {
    const property_id = getState().userProfile.bu_id;
    const url = `/api/audience/list-experiment-audience/${property_id}/?source=googleads`;
    dispatch({
      type: types.EXP_AUDIENCE_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.EXP_AUDIENCE_SUCCESS,
        response,
      });
    } catch (e) {
      console.log("error in fetching audiences= ", e);
      dispatch({
        type: types.EXP_AUDIENCE_FAILED,
      });
    }
  };
}

export function exp_fb_audience_fetch() {
  return async function (dispatch, getState) {
    const property_id = getState().userProfile.bu_id;
    const url = `/api/audience/list-experiment-audience/${property_id}/?source=facebookads`;
    dispatch({
      type: types.EXP_FB_AUDIENCE_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.EXP_FB_AUDIENCE_SUCCESS,
        response,
      });
    } catch (e) {
      console.log("error in fetching audiences= ", e);
      dispatch({
        type: types.EXP_FB_AUDIENCE_FAILED,
      });
    }
  };
}

export function exp_fb_adset_fetch() {
  return async function (dispatch, getState) {
    const property_id = getState().userProfile.bu_id;
    const url = `/api/experiments/list-ad-sets-meta/${property_id}`;
    dispatch({
      type: types.EXP_ADSETS_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.EXP_ADSETS_SUCCESS,
        resultData,
      });
    } catch (e) {
      console.log("error in Fetching adsets= ", e);
      dispatch({
        type: types.EXP_ADSETS_FAILED,
      });
    }
  };
}

export function exp_campaign_fetch() {
  return async function (dispatch, getState) {
    const property_id = getState().userProfile.bu_id;
    const url = `/api/experiments/list-campaigns-google/${property_id}`;
    dispatch({
      type: types.EXP_CAMPAIGN_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.EXP_CAMPAIGN_SUCCESS,
        resultData,
      });
    } catch (e) {
      console.log("error in Fetching campaigns= ", e);
      dispatch({
        type: types.EXP_CAMPAIGN_FAILED,
      });
    }
  };
}

export function exp_fb_campaign_fetch() {
  return async function (dispatch, getState) {
    const property_id = getState().userProfile.bu_id;
    const url = `/api/experiments/list-campaigns-meta/${property_id}`;
    dispatch({
      type: types.EXP_FB_CAMPAIGN_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.EXP_FB_CAMPAIGN_SUCCESS,
        resultData,
      });
    } catch (e) {
      console.log("error in Fetching campaigns= ", e);
      dispatch({
        type: types.EXP_FB_CAMPAIGN_FAILED,
      });
    }
  };
}

export function fetch_experiment_details(id) {
  return async function (dispatch, getState) {
    const property_id = getState().userProfile.bu_id;
    const url = `/api/experiments/experiment-data/${property_id}/${id}/`;
    dispatch({
      type: types.FETCH_EXPERIMENT_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.FETCH_EXPERIMENT_SUCCESS,
        response,
      });
    } catch (e) {
      console.log("error in saving= ", e);
      dispatch({
        type: types.FETCH_EXPERIMENT_FAILED,
      });
    }
  };
}

export function fetch_experiment_execution_status(id) {
  return async function (dispatch, getState) {
    const property_id = getState().userProfile.bu_id;
    const url = `/api/experiments/get_execution_status/${property_id}/${id}/`;
    // const url ="/executionstatus.JSON"
    dispatch({
      type: types.FETCH_EXPERIMENT_EXECUTION_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.FETCH_EXPERIMENT_EXECUTION_SUCCESS,
        response,
      });
    } catch (e) {
      console.log("error in saving= ", e);
      dispatch({
        type: types.FETCH_EXPERIMENT_EXECUTION_FAILED,
      });
    }
  };
}

export function exp_launch(data) {
  return async function (dispatch, getState) {
    const property_id = getState().userProfile.bu_id;
    const url = `/api/experiments/launch_experiment/${property_id}`;
    dispatch({
      type: types.EXP_LAUNCH_REQUEST,
    });
    try {
      let response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.EXP_LAUNCH_SUCCESS,
        response,
      });
    } catch (e) {
      console.log("error while launching Experiment = ", e);
      dispatch({
        type: types.EXP_LAUNCH_FAILED,
      });
    }
  };
}

export function exp_budget(data, id) {
  return async function (dispatch, getState) {
    const property_id = getState().userProfile.bu_id;
    const url = `/api/experiments/experiment-campaign-budgets/${property_id}/${id}/`;
    dispatch({
      type: types.EXP_BUDGET_REQUEST,
    });
    try {
      let response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      await dispatch({
        type: types.EXP_BUDGET_SUCCESS,
        response,
      });
    } catch (e) {
      console.log("error while sending budget data = ", e);
      let response;
      if (e.response) {
        response = e.response;
      }
      await dispatch({
        type: types.EXP_BUDGET_FAILED,
        response,
      });
    }
  };
}

export function fetch_all_experiments_list() {
  return async function (dispatch, getState) {
    const property_id = getState().userProfile.bu_id;
    const url = `/api/experiments/experiment-data/${property_id}/`;
    dispatch({
      type: types.FETCH_ALL_EXPERIMENTS_LIST_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.FETCH_ALL_EXPERIMENTS_LIST_SUCCESS,
        resultData,
      });
    } catch (e) {
      console.log("error in fetching all experiments list= ", e);
      dispatch({
        type: types.FETCH_ALL_EXPERIMENTS_LIST_FAILED,
      });
    }
  };
}
export function clearExperimentStatus() {
  return async function (dispatch) {
    let response = { data: [] };
    dispatch({
      type: types.CREATE_EXPERIMENT_SUCCESS,
      response,
    });
  };
}

export function clearExperimentDeleteStatus() {
  return async function (dispatch) {
    let response = { data: [], status: "" };
    dispatch({
      type: types.DELETE_EXPERIMENT_SUCCESS,
      response,
    });
  };
}

// export function clearFetchExperimentStatus() {
//   return async function (dispatch) {
//     let response = { data: []};
//     dispatch({
//       type: types.FETCH_EXPERIMENT_SUCCESS,
//       response,
//     });
//   };
// }

export function clearExpLaunchStatus() {
  return async function (dispatch) {
    let response = { data: [], status: "" };
    dispatch({
      type: types.EXP_LAUNCH_SUCCESS,
      response,
    });
  };
}

export function clearExpBudgetStatus() {
  return async function (dispatch) {
    let response = { data: [], status: "" };
    dispatch({
      type: types.EXP_BUDGET_SUCCESS,
      response,
    });
  };
}

export function clearExpAudience() {
  return async function (dispatch) {
    let response = { data: [], status: "" };
    dispatch({
      type: types.EXP_AUDIENCE_SUCCESS,
      response,
    });
  };
}

export function fetch_predefined_exp() {
  return async function (dispatch, getState) {
    const property_id = getState().userProfile.bu_id;
    const url = `api/experiments/predefined-experiments/${property_id}/`;
    dispatch({
      type: types.FETCH_PREDEFINED_EXP_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.FETCH_PREDEFINED_EXP_SUCCESS,
        resultData,
      });
    } catch (e) {
      console.log("error in fetching predefined experiments = ", e);
      dispatch({
        type: types.FETCH_PREDEFINED_EXP_FAILED,
      });
    }
  };
}

export function fetch_data_sources() {
  return async function (dispatch, getState) {
    // const url = `/datasources.JSON`;
    const property_id = getState().userProfile.bu_id;
    const url = `api/core/get_data_sources/${property_id}/`;
    dispatch({
      type: types.FETCH_DATA_SOURCE_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.FETCH_DATA_SOURCE_SUCCESS,
        resultData,
      });
    } catch (e) {
      console.log("error in fetching all datasource's list= ", e);
      dispatch({
        type: types.FETCH_DATA_SOURCE_FAILED,
      });
    }
  };
}

export function delete_data_sources(dataSourceId) {
  return async function (dispatch, getState) {
    const property_id = getState().userProfile.bu_id;
    const url = `api/core/delete-data-source/${property_id}/${dataSourceId}/`;
    dispatch({
      type: types.DELETE_DATA_SOURCE_REQUEST,
    });
    try {
      let response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.DELETE_DATA_SOURCE_SUCCESS,
        resultData,
      });
    } catch (e) {
      console.log("error in fetching all datasource's list= ", e);
      dispatch({
        type: types.DELETE_DATA_SOURCE_FAILED,
      });
    }
  };
}


export function fetch_exp_result_test_control_stats(id) {
  return async function (dispatch, getState) {
    const property_id = getState().userProfile.bu_id;
    const url = `api/experiments/experiment-results/${property_id}/${id}`;
    dispatch({
      type: types.FETCH_EXP_RESULT_TEST_CONTROL_STATS_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.FETCH_EXP_RESULT_TEST_CONTROL_STATS_SUCCESS,
        resultData,
      });
    } catch (e) {
      console.log("error in fetching experiment result campaign stats= ", e);
      dispatch({
        type: types.FETCH_EXP_RESULT_TEST_CONTROL_STATS_FAILED,
      });
    }
  };
}

export function fetch_exp_result_campaign_stats(id) {
  return async function (dispatch, getState) {
    const property_id = getState().userProfile.bu_id;
    const url = `api/experiments/experiments-campaign-level-stats/${property_id}/${id}`;
    dispatch({
      type: types.FETCH_EXP_RESULT_CAMP_STATS_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.FETCH_EXP_RESULT_CAMP_STATS_SUCCESS,
        resultData,
      });
    } catch (e) {
      console.log("error in fetching experiment result campaign stats= ", e);
      dispatch({
        type: types.FETCH_EXP_RESULT_CAMP_STATS_FAILED,
      });
    }
  };
}

export function fetch_customer_migration_data() {
  return async function (dispatch, getState) {
    // const property_id = getState().userProfile.bu_id;
    const url = `/api/trend/get_user_segment_changes/`;
    // const url = `customerMigration.JSON`;
    dispatch({
      type: types.FETCH_CUSTOMER_MIGRATION_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.FETCH_EXPFETCH_CUSTOMER_MIGRATION_SUCCESS,
        resultData,
      });
    } catch (e) {
      console.log("error in fetching experiment result campaign stats= ", e);
      dispatch({
        type: types.FETCH_CUSTOMER_MIGRATION_FAILED,
      });
    }
  };
}

export const setConversionPathFilterOptionsList = (payload) => ({
  type: types.SET_CONVERSION_PATH_FILTER_OPTIONS_LIST,
  payload,
});

export function fetch_inmarket_affinity() {
  return async function (dispatch, getState) {
    const property_id = getState().userProfile.bu_id;
    const url = `api/google-ads-sync/list-in-market-affinity-segments/${property_id}`;
    dispatch({
      type: types.INMARKET_AFFINITY_REQUEST,
    });
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      let resultData = await response.data;
      dispatch({
        type: types.INMARKET_AFFINITY_SUCCESS,
        resultData,
      });
      // console.log(resultData,"review data status order")
    } catch (e) {
      console.log("error in fetching inmarket and affinity = ", e);
      let resultData;
      if (e.response) {
        resultData = e.response;
      }
      dispatch({
        type: types.INMARKET_AFFINITY_FAILURE,
        resultData,
      });
    }
  };
}

