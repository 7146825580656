import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { border, cardShadow } from "../../assets/jss/commonStyles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { capitalCase } from "change-case";
import {
  set_a_group_filter,
  set_all_group_filter,
  set_b_group_filter,
  set_c_group_filter,
} from "../../actions/actions";
import { OrangeFilledButton } from "../buttons";
import { connect, useSelector } from "react-redux";
import {
  channelList,
  countryList,
  dayList,
  deviceList,
  graphRegion,
  hourList,
  stateList,
} from "./commonParamPage";
import { GAEventTracker } from "../../index";
import CustomerGroup from "../getGroupDetails";
import ConversionPathFilters from "./conversionPathFilters";

export const conversionPathTabValue = "convPaths";

export const CustomSelect = withStyles((theme) => ({
  select: {
    right: 0,
    width: 180,
    textAlign: "left",
    border: "1px solid #1e334e",
    "&$disabled": {
      color: theme.palette.primary.main,
      WebkitTextFillColor: theme.palette.primary.main,
      border: border,
      opacity: "0.5 !important",
    },
  },
  disabled: {},
  icon: {
    top: "calc(50% - 16px)",
    width: "2em",
    height: "2em",
    color: theme.palette.primary.main,
    border: "none",
  },
  outlined: {
    padding: "6px 14px",
    fontSize: "0.875rem",
  },
}))(Select);

export const CustomCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    opacity: 0.5,
    fontSize: 22,
  },
  checked: {
    color: theme.palette.secondary.light,
    opacity: 1,
  },
}))(Checkbox);

function FilterWindow(props) {
  const theme = useTheme();
  const {
    segment,
    handlePopperClose,
    set_all_group_filter,
    set_a_group_filter,
    set_b_group_filter,
    set_c_group_filter,
    filterStates,
    businessProfile,
    hasLXRTracking,
    dataSourceLength,
    trackingReady,
  } = props;

  const tabValue = useSelector(
    (state) => state?.application_states?.segmentPageTab
  );

  const conversionPathFilterObject = useSelector(
    (state) => state?.filterStates?.conversionPathFilterObj
  );

  const [conversionPathFilterOptions, setConversionPathFilterOptions] =
    useState(conversionPathFilterObject);

  const filter = useMemo(() => {
    switch (segment) {
      case "all":
        return filterStates?.filterAll;
      case "a":
        return filterStates?.filterA;
      case "b":
        return filterStates.filterB;
      case "c":
        return filterStates?.filterC;
      default:
        return {};
    }
  }, [segment, filterStates]);

  const [dayFilter, setDayFilter] = useState(Boolean(filter?.day_of_week));
  const [hourFilter, setHourFilter] = useState(
    Boolean(filter?.hour) || filter?.hour === 0
  );
  const [countryFilter, setCountryFilter] = useState(Boolean(filter?.country));
  const [stateFilter, setStateFilter] = useState(Boolean(filter?.region));
  const [deviceFilter, setDeviceFilter] = useState(Boolean(filter?.device));
  const [channelFilter, setChannelFilter] = useState(Boolean(filter?.channel));

  const [conversionPathStartFilter, setConversionPathFilter] = useState(
    Boolean(filter?.pathStartsWith)
  );

  const [conversionPathEndsFilter, setConversionPathEndsFilter] = useState(
    Boolean(filter?.pathEndsWith)
  );

  const [day, setDay] = useState(
    Object.keys(filter)?.length
      ? filter?.day_of_week
        ? filter?.day_of_week
        : dayList?.[0]
      : dayList?.[0]
  );
  const [hour, setHour] = useState(
    Object.keys(filter)?.length
      ? filter?.hour
        ? filter?.hour
        : hourList?.[0]
      : hourList?.[0]
  );
  const [country, setCountry] = useState(
    Object.keys(filter)?.length
      ? filter?.country
        ? filter?.country
        : countryList?.[0]
      : countryList?.[0]
  );
  const [state, setState] = useState(
    Object.keys(filter)?.length
      ? filter?.region
        ? filter?.region
        : stateList?.[0]
      : stateList?.[0]
  );
  const [device, setDevice] = useState(
    Object.keys(filter)?.length
      ? filter?.device
        ? filter?.device
        : deviceList?.[0]
      : deviceList?.[0]
  );

  const [conversionPathStart, setConversionPathStart] = useState(
    Object.keys(filter)?.length
      ? filter?.pathStartsWith
        ? filter?.pathStartsWith
        : conversionPathFilterObject?.startsWithChannelsArray?.[0]
      : conversionPathFilterObject?.startsWithChannelsArray?.[0]
  );

  const channelsListBasedOnTabValue = useMemo(() => {
    if (tabValue === conversionPathTabValue) {
      return conversionPathFilterOptions?.containsChannelArray;
    } else {
      return channelList;
    }
  }, [tabValue]);

  const [channel, setChannel] = useState(
    Object.keys(filter)?.length
      ? filter?.channel
        ? filter?.channel
        : channelsListBasedOnTabValue?.[0]
      : channelsListBasedOnTabValue?.[0]
  );

  const [conversionPathEnds, setConversionPathEnds] = useState(
    Object.keys(filter)?.length
      ? filter?.pathEndsWith
        ? filter?.pathEndsWith
        : conversionPathFilterOptions?.endsWithChannelArray?.[0]
      : conversionPathFilterOptions?.endsWithChannelArray?.[0]
  );

  const handleDayChange = (e) => {
    setDay(e.target.value);
  };
  const handleHourChange = (e) => {
    setHour(e.target.value);
  };
  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };
  const handleStateChange = (e) => {
    setState(e.target.value);
  };
  const handleDeviceChange = (e) => {
    setDevice(e.target.value);
  };
  const handleChannelChange = (e) => {
    setChannel(e.target.value);
  };
  const handleDayFilterChange = (e) => {
    setDayFilter(e.target.checked);
  };
  const handleHourFilterChange = (e) => {
    setHourFilter(e.target.checked);
  };
  const handleCountryFilterChange = (e) => {
    setCountryFilter(e.target.checked);
  };
  const handleStateFilterChange = (e) => {
    setStateFilter(e.target.checked);
  };
  const handleDeviceFilterChange = (e) => {
    setDeviceFilter(e.target.checked);
  };
  const handleChannelFilterChange = (e) => {
    setChannelFilter(e.target.checked);
  };

  const handleConversionPathStartFilterChange = (e) => {
    setConversionPathFilter(e.target.checked);
  };

  const handleConversionPathStartChange = (e) => {
    setConversionPathStart(e.target.value);
  };

  const handleConversionPathEndsFilterChange = (e) => {
    setConversionPathEndsFilter(e.target.checked);
  };

  const handleConversionPathEndsChange = (e) => {
    setConversionPathEnds(e.target.value);
  };

  const handleFilter = (filterName, filterValue) => {
    switch (segment) {
      case "all":
        set_all_group_filter(filterName, filterValue);
        break;
      case "a":
        set_a_group_filter(filterName, filterValue);
        break;
      case "b":
        set_b_group_filter(filterName, filterValue);
        break;
      case "c":
        set_c_group_filter(filterName, filterValue);
        break;
      default:
        return null;
    }
  };
  const handleCancel = () => {
    if (Boolean(filter?.day_of_week)) {
      setDay(filter?.day_of_week);
    }
    if (Boolean(filter?.hour) || filter?.hour === 0) {
      setHour(filter?.hour);
    }
    if (Boolean(filter?.country)) {
      setCountry(filter?.country);
    }
    if (Boolean(filter?.region)) {
      setState(filter?.region);
    }
    if (Boolean(filter?.device)) {
      setDevice(filter?.device);
    }
    if (Boolean(filter?.channel)) {
      setChannel(filter?.channel);
    }
  };
  const handleApply = () => {
    if (dayFilter) {
      handleFilter("day_of_week", day);
    } else {
      handleFilter("clear", "day_of_week");
    }
    if (hourFilter) {
      handleFilter("hour", hour);
    } else {
      handleFilter("clear", "hour");
    }
    if (countryFilter) {
      handleFilter("country", country);
    } else {
      handleFilter("clear", "country");
    }
    if (stateFilter) {
      handleFilter("region", state);
    } else {
      handleFilter("clear", "region");
    }
    if (deviceFilter) {
      handleFilter("device", device);
    } else {
      handleFilter("clear", "device");
    }
    if (channelFilter) {
      handleFilter("channel", channel);
    } else {
      handleFilter("clear", "channel");
    }

    if (conversionPathStartFilter) {
      handleFilter("pathStartsWith", conversionPathStart);
    } else {
      handleFilter("clear", "pathStartsWith");
    }

    if (conversionPathEndsFilter) {
      handleFilter("pathEndsWith", conversionPathEnds);
    } else {
      handleFilter("clear", "pathEndsWith");
    }
  };

  useEffect(() => {
    setConversionPathFilterOptions(conversionPathFilterObject);
  }, [conversionPathFilterObject]);

  return (
    <React.Fragment>
      <div
        style={{
          backgroundColor: theme.palette.common.white,
          width: 300,
          border: border,
          boxShadow: cardShadow,
          borderRadius: 4,
        }}
      >
        {tabValue !== conversionPathTabValue ? (
          <React.Fragment>
            <Box p={2} py={1} borderBottom={border}>
              <Typography color={"primary"} style={{ fontWeight: 700 }}>
                Day of Week
              </Typography>
              <Box style={{ textAlign: "-webkit-right" }}>
                <Box py={0.5}>
                  <CustomCheckbox
                    color={"default"}
                    checked={dayFilter}
                    onChange={(e) => {
                      handleDayFilterChange(e);
                      GAEventTracker(
                        CustomerGroup(segment)().name,
                        "Day Filter Check Box",
                        e.target.checked
                      );
                    }}
                    indeterminate
                    indeterminateIcon={<CheckCircleIcon />}
                  />
                  <CustomSelect
                    disabled={!dayFilter}
                    variant={"outlined"}
                    value={day}
                    onChange={(e) => {
                      handleDayChange(e);
                      GAEventTracker(
                        CustomerGroup(segment)().name,
                        "Day Filter Value",
                        e.target.value
                      );
                    }}
                  >
                    {dayList.map((day, i) => (
                      <MenuItem value={day} key={i}>
                        {day}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                </Box>
              </Box>
            </Box>
            <Box p={2} py={1} borderBottom={border}>
              <Typography color={"primary"} style={{ fontWeight: 700 }}>
                Hour
              </Typography>
              <Box style={{ textAlign: "-webkit-right" }}>
                <Box py={0.5}>
                  <CustomCheckbox
                    color={"default"}
                    checked={hourFilter}
                    onChange={(e) => {
                      handleHourFilterChange(e);
                      GAEventTracker(
                        CustomerGroup(segment)().name,
                        "Hour Filter Check Box",
                        e.target.checked
                      );
                    }}
                    indeterminate
                    indeterminateIcon={<CheckCircleIcon />}
                  />
                  <CustomSelect
                    disabled={!hourFilter}
                    renderValue={(value) => {
                      return `${value} Hours`;
                    }}
                    variant={"outlined"}
                    value={hour}
                    onChange={(e) => {
                      handleHourChange(e);
                      GAEventTracker(
                        CustomerGroup(segment)().name,
                        "Hour Filter Value",
                        e.target.value
                      );
                    }}
                  >
                    {hourList.map((i, index) => (
                      <MenuItem value={i} key={index}>
                        {i}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                </Box>
              </Box>
            </Box>
            <Box p={2} py={1} borderBottom={border}>
              <Typography color={"primary"} style={{ fontWeight: 700 }}>
                Country
              </Typography>
              <Box style={{ textAlign: "-webkit-right" }}>
                <Box py={0.5}>
                  <CustomCheckbox
                    color={"default"}
                    checked={countryFilter}
                    onChange={(e) => {
                      handleCountryFilterChange(e);
                      GAEventTracker(
                        CustomerGroup(segment)().name,
                        "Country Filter Check Box",
                        e.target.checked
                      );
                    }}
                    indeterminate
                    indeterminateIcon={<CheckCircleIcon />}
                  />
                  <CustomSelect
                    disabled={!countryFilter}
                    variant={"outlined"}
                    value={country}
                    onChange={(e) => {
                      handleCountryChange(e);
                      GAEventTracker(
                        CustomerGroup(segment)().name,
                        "Country Filter Value",
                        e.target.value
                      );
                    }}
                  >
                    {countryList.map((country, i) => (
                      <MenuItem value={country} key={i}>
                        {country}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                </Box>
              </Box>
            </Box>
            {graphRegion === "usa" ? (
              <Box p={2} py={1} borderBottom={border}>
                <Typography color={"primary"} style={{ fontWeight: 700 }}>
                  Region
                </Typography>
                <Box style={{ textAlign: "-webkit-right" }}>
                  <Box py={0.5}>
                    <CustomCheckbox
                      color={"default"}
                      checked={stateFilter}
                      onChange={(e) => {
                        handleStateFilterChange(e);
                        GAEventTracker(
                          CustomerGroup(segment)().name,
                          "Region Filter Check Box",
                          e.target.checked
                        );
                      }}
                      indeterminate
                      indeterminateIcon={<CheckCircleIcon />}
                    />
                    <CustomSelect
                      disabled={!stateFilter}
                      variant={"outlined"}
                      value={state}
                      onChange={(e) => {
                        handleStateChange(e);
                        GAEventTracker(
                          CustomerGroup(segment)().name,
                          "Region Filter Value",
                          e.target.value
                        );
                      }}
                    >
                      {stateList.map((state, i) => (
                        <MenuItem value={state} key={i}>
                          {state}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  </Box>
                </Box>
              </Box>
            ) : null}
            {hasLXRTracking && dataSourceLength === 1 ? (
              <Box p={2} py={1} borderBottom={border}>
                <Typography color={"primary"} style={{ fontWeight: 700 }}>
                  Device
                </Typography>
                <Box style={{ textAlign: "-webkit-right" }}>
                  <Box py={0.5}>
                    <CustomCheckbox
                      color={"default"}
                      checked={deviceFilter}
                      disabled={deviceList?.length === 0?true:false}
                      onChange={(e) => {
                        handleDeviceFilterChange(e);
                        GAEventTracker(
                          CustomerGroup(segment)().name,
                          "Device Filter Check Box",
                          e.target.checked
                        );
                      }}
                      indeterminate
                      indeterminateIcon={<CheckCircleIcon />}
                    />
                    <CustomSelect
                      disabled={!deviceFilter}
                      variant={"outlined"}
                      value={device}
                      onChange={(e) => {
                        handleDeviceChange(e);
                        GAEventTracker(
                          CustomerGroup(segment)().name,
                          "Device Filter Value",
                          e.target.value
                        );
                      }}
                    >
                      {deviceList.map((device, i) => (
                        <MenuItem value={device} key={i}>
                          {capitalCase(device)}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  </Box>
                </Box>
              </Box>
            ) : hasLXRTracking && dataSourceLength > 1 ? (
              trackingReady ? (
                <Box p={2} py={1} borderBottom={border}>
                  <Typography color={"primary"} style={{ fontWeight: 700 }}>
                    Device
                  </Typography>
                  <Box style={{ textAlign: "-webkit-right" }}>
                    <Box py={0.5}>
                      <CustomCheckbox
                        color={"default"}
                        checked={deviceFilter}
                        disabled={deviceList?.length === 0?true:false}
                        onChange={(e) => {
                          handleDeviceFilterChange(e);
                          GAEventTracker(
                            CustomerGroup(segment)().name,
                            "Device Filter Check Box",
                            e.target.checked
                          );
                        }}
                        indeterminate
                        indeterminateIcon={<CheckCircleIcon />}
                      />
                      <CustomSelect
                        disabled={!deviceFilter}
                        variant={"outlined"}
                        value={device}
                        onChange={(e) => {
                          handleDeviceChange(e);
                          GAEventTracker(
                            CustomerGroup(segment)().name,
                            "Device Filter Value",
                            e.target.value
                          );
                        }}
                      >
                        {deviceList.map((device, i) => (
                          <MenuItem value={device} key={i}>
                            {capitalCase(device)}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </Box>
                  </Box>
                </Box>
              ) : null
            ) : null}
          </React.Fragment>
        ) : null}

        {hasLXRTracking && dataSourceLength === 1 ? (
          <ConversionPathFilters
            tabValue={tabValue}
            conversionPathStartFilter={conversionPathStartFilter}
            conversionPathFilterOptions={conversionPathFilterOptions}
            handleConversionPathStartFilterChange={
              handleConversionPathStartFilterChange
            }
            handleConversionPathStartChange={handleConversionPathStartChange}
            handleConversionPathEndsFilterChange={
              handleConversionPathEndsFilterChange
            }
            conversionPathStart={conversionPathStart}
            conversionPathEndsFilter={conversionPathEndsFilter}
            conversionPathEnds={conversionPathEnds}
            handleConversionPathEndsChange={handleConversionPathEndsChange}
            channelFilter={channelFilter}
            channelsListBasedOnTabValue={channelsListBasedOnTabValue}
            handleChannelFilterChange={handleChannelFilterChange}
            channel={channel}
            handleChannelChange={handleChannelChange}
          />
        ) : hasLXRTracking && dataSourceLength > 1 ? (
          trackingReady ? (
            <ConversionPathFilters
              tabValue={tabValue}
              conversionPathStartFilter={conversionPathStartFilter}
              conversionPathFilterOptions={conversionPathFilterOptions}
              handleConversionPathStartFilterChange={
                handleConversionPathStartFilterChange
              }
              handleConversionPathStartChange={handleConversionPathStartChange}
              handleConversionPathEndsFilterChange={
                handleConversionPathEndsFilterChange
              }
              conversionPathStart={conversionPathStart}
              conversionPathEndsFilter={conversionPathEndsFilter}
              conversionPathEnds={conversionPathEnds}
              handleConversionPathEndsChange={handleConversionPathEndsChange}
              channelFilter={channelFilter}
              channelsListBasedOnTabValue={channelsListBasedOnTabValue}
              handleChannelFilterChange={handleChannelFilterChange}
              channel={channel}
              handleChannelChange={handleChannelChange}
            />
          ) : null
        ) : null}
        <Box py={1} px={2} style={{ textAlign: "end" }}>
          <Button
            onClick={() => {
              handleCancel();
              handlePopperClose();
              GAEventTracker(
                CustomerGroup(segment)().name,
                "Graph Filter Cancel"
              );
            }}
            style={{
              border: "1px solid rgb(30, 51, 78,0.3)",
              textTransform: "none",
              fontSize: "1rem",
            }}
          >
            Cancel
          </Button>
          &emsp;
          <OrangeFilledButton
            onClick={() => {
              handleApply();
              handlePopperClose();
              GAEventTracker(
                CustomerGroup(segment)().name,
                "Graph Filter Apply"
              );
            }}
            variant="contained"
            style={{ fontWeight: 700 }}
          >
            Apply
          </OrangeFilledButton>
        </Box>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  filterStates: state.filterStates,
  businessProfile: state.businessProfile,
});
const mapDispatchToProps = (dispatch) => ({
  set_all_group_filter: (filterName, filterValue) =>
    dispatch(set_all_group_filter(filterName, filterValue)),
  set_a_group_filter: (filterName, filterValue) =>
    dispatch(set_a_group_filter(filterName, filterValue)),
  set_b_group_filter: (filterName, filterValue) =>
    dispatch(set_b_group_filter(filterName, filterValue)),
  set_c_group_filter: (filterName, filterValue) =>
    dispatch(set_c_group_filter(filterName, filterValue)),
});
export default connect(mapStateToProps, mapDispatchToProps)(FilterWindow);
