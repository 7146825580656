import React from "react";
import {
  IconButton,
  Pagination,
  Select,
  TableCell,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from "@mui/icons-material";

export const CustomSelect = withStyles((theme) => ({
  root: {
    padding: "0px",
    borderBottom: 0,
  },
  icon: {
    top: 0,
    // right: "25%",
    width: "1.5rem",
    height: "1.5rem",
  },
}))(Select);

export const useStyles = makeStyles((theme) => ({
  paginationRoot: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));
export const useStyles2 = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  container: {
    maxHeight: 320,
  },
}));

export const CustomTablePagination = withStyles((theme) => ({
  caption: {
    fontSize: 14,
  },
  input: {
    fontSize: 14,
  },
  selectIcon: {
    fontSize: 24,
  },
}))(TablePagination);

export const CustomPagination = withStyles((theme) => ({
  ul: {
    justifyContent: "right",
    "& .MuiPaginationItem-root": {
      backgroundColor: "#fff",
      "&.Mui-selected": {
        fontWeight: 700,
      },
    },
  },
  selected: {},
}))(Pagination);

export const CustomIconButton = withStyles((theme) => ({
  root: {
    fontSize: 16,
  },
}))(IconButton);

export const CustomTableCell = withStyles((theme) => ({
  root: {
    fontSize: 14,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 4,
    paddingRight: 4,
    minWidth: 100,
  },
}))(TableCell);

export const CustomTableCell2 = withStyles((theme) => ({
  root: {
    fontSize: 14,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 4,
    paddingRight: 4,
    minWidth: 100,
  },
}))(TableCell);

export const CustomTableCell3 = withStyles((theme) => ({
  root: {
    fontSize: 14,
    borderRight: "1px solid #F2F6E0",
    backgroundColor: "#F2F6F9",
    color: "#4E4E4E",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 4,
    paddingRight: 4,
    // minWidth: 100,
    fontWeight: 500,
  },
}))(TableCell);

export const CustomTableCell4 = withStyles((theme) => ({
  root: {
    fontSize: 14,
    padding: 0,
    paddingTop: 10,
    paddingBottom: 10,
    // paddingLeft: 4,
    borderRight: "1px solid #F2F6E0",
    // paddingRight: 4,
    // minWidth: 100,
    background: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.grey.coolGrey}`,
    // border: `1px solid ${theme.palette.grey.coolGrey}`,
  },
}))(TableCell);

export const CustomTableCell5 = withStyles((theme) => ({
  root: {
    textAlign: "center",
    fontSize: "24px",
    fontWeight: 500,
    lineHeight: "28.13px",
  },
}))(TableCell);

export const CustomTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.backgroundColors.overview,
    },
  },
}))(TableRow);

export const CustomTableHeaderCell = withStyles((theme) => ({
  root: {
    fontSize: "0.875rem",
    whiteSpace: "noWrap",
    padding: "1rem 0rem",
    fontWeight: "600",
    fontFamily: '"Lato", sans-serif',
    backgroundColor: "#F8FAFC",
    color: "#4E4E4E",
    border: "none",
    textAlign: "left",
    zIndex: 3,
  },
}))(TableCell);

export const CustomTableSortLabel = withStyles((theme) => ({
  root: {
    "&:hover": {
      color: theme.palette.common.white,
    },
    "&$active, &$active:hover": {
      color: theme.palette.common.white,
    },
  },
  active: {},
  icon: {
    color: `${theme.palette.common.white} !important`,
  },
}))(TableSortLabel);

export const CustomTableSortLabel1 = withStyles((theme) => ({
  root: {
    "&:hover": {
      color: "#4E4E4E",
    },
    "&$active, &$active:hover": {
      color: "#4E4E4E",
    },
  },
  active: {
    color: "#4E4E4E",
  },
  icon: {
    color: `#4E4E4E !important`,
  },
}))(TableSortLabel);

export function TablePaginationActions(props) {
  const classes = useStyles();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    window.scrollTo(0, 1007);
  };

  return (
    <div className={classes.paginationRoot}>
      <CustomIconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPage />
      </CustomIconButton>
      <CustomIconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </CustomIconButton>
      <CustomIconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </CustomIconButton>
      <CustomIconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPage />
      </CustomIconButton>
    </div>
  );
}

export function descendingComparator(a, b, orderBy) {
  const valueA = a?.[orderBy]; // replace 'property' with the actual property you want to compare
  const valueB = b?.[orderBy];

  // Handle cases where valueA or valueB is null
  if (valueA === null && valueB === null) return 0;
  if (valueA === null) return 1; // You can adjust this to -1 if you want nulls to be considered smaller
  if (valueB === null) return -1; // You can adjust this to 1 if you want nulls to be considered smaller

  // If both values are non-null, compare them normally
  if (valueB < valueA) return -1;
  if (valueB > valueA) return 1;
  return 0;
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
